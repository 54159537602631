import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal, Header, Button } from 'semantic-ui-react'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import { LOAD_SINGLE_PROJECT_QUERY } from '../../graphql/project'
import MessageContext from '../../contexts/MessageContext'

const ReportNoShow = ({ job, children }) => {
  const [open, setOpen] = useState(false)
  const { setSuccessMsg } = useContext(MessageContext)

  const [pendingNoShow, { loading }] = useMutation(UPDATE_JOB_MUTATION, {
    refetchQueries: [
      { query: LOAD_SINGLE_PROJECT_QUERY, variables: { id: job.project.id } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setSuccessMsg({
        title: 'Your report has been submitted',
        content: `No further action is needed from you at the moment.
          Our sincere apologies for the trouble. Please don't hesitate to reach out to us at
          help@dexterexperts.com if you have any questions/concerns.
        `,
      })
    },
  })

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>Report a No Show</Modal.Header>
      <Modal.Content>
        <Header>
          {`Are you sure you want to mark ${job.project.title} as a no show ?`}
        </Header>
        <p>
          Please stay on the conference line for at least 10 minutes as a
          courtesy. Once submitted, this meeting will be canceled.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <span className="subtext">This action cannot be undone</span>
        <Button basic onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          primary
          loading={loading}
          disabled={loading}
          onClick={() =>
            pendingNoShow({
              variables: {
                userId: job.user.id,
                projectId: job.project.id,
                job: { overall_status: 'No Show' },
              },
            })
          }
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ReportNoShow
