import React, { useEffect } from 'react'
import { appendCalendly } from '../../lib/calendly'

const CalendlyModal = ({ link, index }) => {
  useEffect(() => {
    appendCalendly()
  }, [])

  return (
    <div
      className="modal fade calendly-section"
      id={`calendlyModal${index}`}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              <span style={{ color: 'black' }}>Set up schedule</span>
            </h3>
          </div>
          <div className="modal-body">
            <div
              className="calendly-inline-widget"
              data-url={link}
              style={{
                minWidth: '320px',
                height: '580px',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendlyModal
