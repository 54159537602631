export const ORG_SIZE = [
  '1-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10001+',
]

export const ORG_TYPES = [
  'Biopharma',
  'Biotech',
  'Applied Industry',
  'CRO',
  'CMO / CDMO',
  'OEM',
  'Consultancy',
  'Medical Devices',
  'Manufacturer',
  'Reagants Provider',
  'Professional Services',
  'Financial Services',
  'Research Institute',
  'Reference Lab',
  'Hospital Lab',
  'University',
  'Academic Medical Center',
  'Community Hospital',
  'Private Practice',
  'Private Practice / Concierge',
  'Intensive Care / ICU',
  'Urgent Care',
  'Ambulatory',
  'Nursing Home',
  'Government Hospital – Academic Medical Center',
  'Government Hospital – Community Hospital',
  'Government Hospital – VA',
  'Independent Community Hospital',
  'Integrated Delivery Network Hospital',
  'Other Network Hospital',
  'Government',
  'Insurance / Payor',
  'Regulatory Body',
  'FDA',
  'CDC',
  'Legal',
  'Quality Control',
  'Life Science Research Tools',
  'Diagnostics',
  'Digital Health',
  'Software',
  'Oil and Gas',
  'Cosmetics',
  'Cancer Center',
  'Hospital',
  'Medical Clinic',
  'Professional Association',
  'Supply Chain',
  'Mental Health Care',
  'Philanthropic Fundraising Services',
  'Procurement',
]
