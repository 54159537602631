import React, { useState, useContext, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Message, Grid } from 'semantic-ui-react'
import MixpanelTracker from '../utils/MixpanelTracker'
import { getWelcomeEmail } from '../components/Emails/WelcomeEmail'
import { SEND_EMAIL_MUTATION } from '../graphql/sendgrid'
import { LOAD_ME_QUERY, UPDATE_USER } from '../graphql/user'
import { CREATE_EXPERT } from '../graphql/expert'
import General from '../components/Forms/General'
import EEO from '../components/Forms/EEO'
import Professional from '../components/Forms/Professional'
import Expertise from '../components/Forms/Expertise'
import RegisterLayout from '../components/Register/RegisterLayout'
import RegisterActions from '../components/Register/RegisterActions'
import Welcome from './Welcome'
import DataContext from '../contexts/DataContext'

const Register = () => {
  const { advisor } = useContext(DataContext)

  const generalRef = useRef(null)
  const professionalRef = useRef(null)
  const expertiseRef = useRef(null)
  const eeoRef = useRef(null)

  const [generalData, setGeneralData] = useState(null)
  const [professionalData, setProfessionalData] = useState(null)
  const [expertiseData, setExpertiseData] = useState(null)
  const [step, setStep] = useState(0)
  const [message, setMessage] = useState(null)

  const [createExpert, { loading }] = useMutation(CREATE_EXPERT)
  const [updateUser] = useMutation(UPDATE_USER, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: LOAD_ME_QUERY,
        data: { me: data.updateUser },
      })
    },
    onCompleted: () => setStep(0),
  })
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)

  const register = (eeoData) => {
    if (!generalData || !professionalData || !expertiseData) {
      return
    }

    const { firstName, lastName, salutation, country } = generalData
    const { linkedinProfile, title, organization, hourlyRate } =
      professionalData
    const { description, functions, technologyTags, medicalSpecialties } =
      expertiseData
    const {
      gender,
      yearOfBirth,
      education,
      ethnicity,
      language,
      veteranStatus,
      disabilityStatus,
    } = eeoData

    createExpert({
      variables: {
        profile: {
          first_name: firstName,
          last_name: lastName,
          salutation,
          country,
          title,
          organization,
          gender,
          yearOfBirth: parseInt(yearOfBirth),
          education,
          ethnicity,
          language,
          veteran_status: veteranStatus,
          disability_status: disabilityStatus,
          linkedin_profile: linkedinProfile,
          hourlyRate: parseInt(hourlyRate),
          functions,
          technology_tags: technologyTags,
          medical_specialties: medicalSpecialties,
          description,
        },
        notifications: {
          status: 'Active',
          reminder_notify: true,
          payment_notify: true,
        },
      },
      update: () => {
        updateUser({
          variables: { input: { firstName, lastName } },
        })
        sendEmail({
          variables: {
            input: {
              from: {
                email: 'help@dexterexperts.com',
                name: 'Dexter Expert Network',
              },
              to: { email: advisor.email },
              subject: 'Welcome to Dexter | Thanks for Joining!',
              text: 'Welcome to Dexter and Thanks for Joining',
              html: getWelcomeEmail(`${firstName} ${lastName}`),
            },
          },
        })
      },
    })
  }

  const onNext = () => {
    setMessage(null)
    switch (step) {
      case 0: {
        const { firstName, lastName, country } = generalRef.current.state
        if (firstName === '') {
          return setMessage('Please fill out your first name.')
        }
        if (lastName === '') {
          return setMessage('Please fill out your last name.')
        }
        if (country === '') {
          return setMessage('Please select your country.')
        }
        setGeneralData(generalRef.current.state)
        MixpanelTracker.trackRegisterGeneralStep(generalRef.current.state)
        setStep(step + 1)
        break
      }
      case 1: {
        const { title, organization, hourlyRate } =
          professionalRef.current.state
        if (title === '') {
          return setMessage('Please fill out your title.')
        }
        if (!organization) {
          return setMessage('Please select your organization.')
        }
        if (parseInt(hourlyRate) === 0 || hourlyRate === '') {
          return setMessage('Please fill out your hourly rate.')
        }
        setProfessionalData(professionalRef.current.state)
        MixpanelTracker.trackRegisterProfessionalStep(
          professionalRef.current.state
        )
        setStep(step + 1)
        break
      }
      case 2:
        setExpertiseData(expertiseRef.current.state)
        MixpanelTracker.trackRegisterExpertiseStep(expertiseRef.current.state)
        setStep(step + 1)
        break
      case 3:
        MixpanelTracker.trackRegisterEEOStep(eeoRef.current.state)
        register(eeoRef.current.state)
        break
      default:
        break
    }
  }

  const onPrev = () => {
    setMessage(null)
    setStep(step - 1)
  }

  const renderForm = () => {
    switch (step) {
      case 0:
        return <General ref={generalRef} isRegister={true} />
      case 1:
        return <Professional ref={professionalRef} />
      case 2:
        return <Expertise ref={expertiseRef} />
      default:
        return <EEO ref={eeoRef} />
    }
  }

  if (advisor.expert) return <Welcome />

  return (
    <Grid container stackable id="main">
      <Grid className="step-progress container">
        <Grid.Row>
          <RegisterLayout step={step} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {message && (
              <Message
                error
                header="Empty fields detected!"
                content={message}
              />
            )}
            {renderForm()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <RegisterActions
              onNext={onNext}
              onPrev={onPrev}
              loading={loading}
              step={step}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid>
  )
}

export default Register
