import React, { useContext } from 'react'
import DefaultPanel from '../components/Payment/DefaultPanel'
import ConnectPanel from '../components/Payment/ConnectPanel'
import QuestionList from '../components/Payment/FAQs/QuestionList'
import DataContext from '../contexts/DataContext'

const Payment = () => {
  const { advisor } = useContext(DataContext)

  return (
    <div id="payment">
      {advisor.expert.stripe_account ? <ConnectPanel /> : <DefaultPanel />}
      <QuestionList />
    </div>
  )
}

export default Payment
