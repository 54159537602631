import React from 'react'
import { Message } from 'semantic-ui-react'
import moment from 'moment-timezone'
import CalendlyModal from '../../../Modals/CalendlyModal'

const MessageHeader = ({ project }) => {
  if (project.type === 'Interview') {
    return 'Success!'
  }
  return (
    <div className="header">
      {'Complete the survey '}
      <a
        href={project.surveyLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline' }}
      >
        here
      </a>
    </div>
  )
}

const Interview = ({ project }) => (
  <div>
    {`Please check your email for a calendar invite, If you
    haven't already done so, click `}
    <a
      href={project.calendly_link || '# '}
      className="active-link"
      data-toggle="modal"
      data-target={`#calendlyModal${project.id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </a>
    {' to schedule a time.'}
  </div>
)

const Survey = ({ remainingDays }) => (
  <div>
    {remainingDays >= 0 ? (
      <div>
        Survey expires in{' '}
        <span style={{ fontWeight: 'bold' }}>{remainingDays}</span>{' '}
        {`day${remainingDays <= 1 ? '' : 's'}`}
      </div>
    ) : (
      <div>Survey has expired.</div>
    )}
  </div>
)

const FullServiceMessage = ({ project }) => {
  const remainingDays = moment(project.end_date).diff(moment(), 'days') + 1
  const getMessageState = () => {
    if (project.type === 'Survey') {
      return { positive: true }
    } else {
      if (remainingDays > 10) {
        return { positive: true }
      } else if (remainingDays > 3 && remainingDays <= 10) {
        return { warning: true }
      } else {
        return { negative: true }
      }
    }
  }
  return (
    <>
      {project.calendar_link && (
        <CalendlyModal index={project.id} link={project.calendly_link} />
      )}
      <Message
        {...getMessageState()}
        attached
        header={<MessageHeader project={project} />}
        content={
          project.type === 'Interview' ? (
            <Interview project={project} />
          ) : (
            <Survey remainingDays={remainingDays} />
          )
        }
      />
    </>
  )
}

export default FullServiceMessage
