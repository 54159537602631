import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Header, List, Accordion, Icon, Button } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'

import { SINGLE_PROJECT_PUBLIC_QUERY } from '../../graphql/project'
import Loading from '../../components/Loading'
import { FAQS } from '../../constants/faqs'
import PublicProjectInfo from '../../components/PublicProject/PublicProjectInfo'
import NotFoundPage from '../NotFoundPage'
import AuthContext from '../../contexts/AuthContext'
import DataContext from '../../contexts/DataContext'
import ExistingResponseAlert from '../../components/PublicProject/ExistingResponseAlert'
import QualificationQuestions from '../../components/QualificationQuestions'

const PublicProjectLanding = () => {
  const { id } = useParams()
  const history = useHistory()

  const { token } = useContext(AuthContext)
  const dataContext = useContext(DataContext)

  const advisor = dataContext?.advisor

  const [showFAQs, setShowFAQs] = useState(false)

  const existingInvite = advisor?.jobs.find((job) => job.project.id === id)
  const hasResponded =
    existingInvite &&
    !['Pending Scheduling', 'Client Reschedule'].includes(
      existingInvite.overall_status
    )

  const { loading, error, data } = useQuery(SINGLE_PROJECT_PUBLIC_QUERY, {
    variables: { id },
  })

  useEffect(() => {
    if (data && data.project.status === 'Active') {
      MixpanelTracker.trackViewPublicProjectLanding(
        advisor,
        data.project,
        hasResponded
      )
    }
  }, [advisor, hasResponded, data])

  if (loading) return <Loading />
  if (error || data.project.status !== 'Active')
    return (
      <NotFoundPage
        message={
          <>
            The project you are looking for is not available at the moment.
            <div>
              Please check again later or contact help@dexterexperts.com for any
              questions.
            </div>
          </>
        }
      />
    )
  const { project } = data
  if (hasResponded) return <ExistingResponseAlert project={project} />

  const setQualificationResponses = (response) => {
    sessionStorage.setItem('qualification', JSON.stringify(response))
  }
  const onNext = () => {
    sessionStorage.setItem('publicProject', JSON.stringify(data.project))
    history.push(`/landing/${token ? 'register' : 'authenticate'}`)
  }

  return (
    <div id="public-project-layout">
      <div id="header">
        <Header size="small">
          You have been invited to a Dexter consulting project
        </Header>
      </div>
      <div id="faqs">
        <Accordion>
          <Accordion.Title
            active={showFAQs}
            onClick={() => setShowFAQs(!showFAQs)}
          >
            <Header sub>
              <Icon name="dropdown" />
              Commonly Asked Questions
            </Header>
          </Accordion.Title>

          <Accordion.Content active={showFAQs}>
            <div id="faqs-content">
              <List>
                {FAQS.map((faq, i) => (
                  <List.Item key={i}>
                    <List.Content>
                      <List.Header>{faq.question}</List.Header>
                      <List.Description>{faq.answer}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </div>
          </Accordion.Content>
        </Accordion>
      </div>
      <div id="about-project">
        <Header sub>About the project</Header>
        <Header size="medium">{project.title}</Header>
        <PublicProjectInfo project={project} />
      </div>
      {project.qualificationQuestions.length > 0 && (
        <QualificationQuestions
          updateQualificationResponses={setQualificationResponses}
          onSubmit={onNext}
          project={project}
        />
      )}
      {project.qualificationQuestions.length === 0 && (
        <div id="public-project-btn">
          <Button
            className="primary-btn"
            primary
            onClick={() => {
              setQualificationResponses([])
              onNext()
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  )
}

export default PublicProjectLanding
