import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { Grid, Loader, Image, Icon } from 'semantic-ui-react'
import { UPLOAD_EXPERT_PICTURE_MUTATION } from '../../graphql/expert'
import DataContext from '../../contexts/DataContext'

const ProfilePicture = () => {
  const match = useRouteMatch('/profile/:page')
  const { advisor } = useContext(DataContext)
  const [profilePicture, setProfilePicture] = useState(
    advisor.expert.profile_picture
  )

  const [uploadExpertPicture, { loading }] = useMutation(
    UPLOAD_EXPERT_PICTURE_MUTATION
  )

  const uploadPhoto = (e) => {
    e.preventDefault()
    const image = e.target.files[0]
    const validity = e.target.validity
    if (validity.valid) {
      uploadExpertPicture({
        variables: { file: image },
        update: (cache, { data }) => {
          setProfilePicture(data.uploadExpertPicture.profile_picture)
        },
      })
    } else {
      alert('Error: File type invalid.')
    }
  }

  return (
    <Grid.Row className="user-profile">
      <Grid.Column
        className="user-pic"
        style={
          !match
            ? { visibility: 'visible' }
            : { visibility: 'hidden', width: '1%', height: '1%' }
        }
      >
        <Loader inverted active={loading} />
        <Image
          src={
            profilePicture ||
            `${process.env.REACT_APP_AWS_S3_URL}/fake_avatar.png`
          }
          style={!match ? { display: 'flex' } : { height: '100px', margin: 0 }}
        />
        <input
          type="file"
          id="getFile"
          style={{ display: 'none' }}
          onChange={uploadPhoto}
        />
        <span
          className="upload-pic-btn"
          onClick={() => {
            document.getElementById('getFile').click()
          }}
        >
          <Icon size="mini" name="upload" />
          <span>Upload photo</span>
        </span>
      </Grid.Column>
    </Grid.Row>
  )
}

export default ProfilePicture
