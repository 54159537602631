import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import Nps from './Nps'
import DataContext from '../contexts/DataContext'
import NavbarLayout from '../components/NavbarLayout'

const ExpertRoute = ({ ...rest }) => {
  const { advisor } = useContext(DataContext)

  const isPublic = sessionStorage.getItem('publicProject')
  if (isPublic) {
    return <Redirect to="/landing/register" />
  }

  if (!advisor.expert) {
    return <Redirect to="/register" />
  }

  return (
    <NavbarLayout>
      <Route {...rest} />
      <Nps />
    </NavbarLayout>
  )
}

export default ExpertRoute
