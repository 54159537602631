import { gql } from '@apollo/client'
import { EXPERT_MODEL, JOB_MODEL } from './modelFields'

export const UPDATE_JOB_MUTATION = gql`
  mutation UpdateJob($userId: ID!, $projectId: ID!, $job: JobInput!) {
    updateJob(userId: $userId, projectId: $projectId, job: $job) {
      id
      user {
        id
        expert {
          ${EXPERT_MODEL}
        }
        jobs {
          ${JOB_MODEL}
          project {
            surveyLink
            preInterviewLink
          }
        }
      }
    }
  }
`

export const COMPLETE_JOB_MUTATION = gql`
  mutation CompleteJob($jobId: ID!) {
    completeJob(jobId: $jobId) {
      id
      user {
        id
        jobs {
          ${JOB_MODEL}
        }
      }
    }
  }
`
