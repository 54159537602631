import React from 'react'
import { Grid } from 'semantic-ui-react'
import QuickLinks from '../components/Dashboard/QuickLinks'
import DashboardContent from '../components/Dashboard/DashboardContent'
import DashboardFooter from '../components/Dashboard/DashboardFooter'

const Dashboard = () => (
  <Grid container stackable id="dashboard">
    <DashboardContent />
    <QuickLinks />
    <DashboardFooter />
  </Grid>
)

export default Dashboard
