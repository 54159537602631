import React, { useState } from 'react'
import { Button, Checkbox } from 'semantic-ui-react'

const RegisterActions = ({ onNext, onPrev, loading, step }) => {
  const [isAgree, setIsAgree] = useState(false)
  const renderUserAgreement = () => {
    if (step === 0) {
      return (
        <Checkbox
          className="agreement"
          label={{
            children: (
              <span>
                {`Creating an account means you accept our `}
                <a
                  href="https://www.dexterexperts.com/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dark-green"
                >
                  Terms of Service
                </a>
                {', and '}
                <a
                  href="https://www.dexterexperts.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dark-green"
                >
                  Privacy Policy
                </a>
              </span>
            ),
          }}
          checked={isAgree}
          onChange={(e, { checked }) => setIsAgree(checked)}
        />
      )
    }
    return null
  }
  return (
    <div>
      {step > 0 && (
        <Button
          positive
          floated="left"
          loading={loading}
          disabled={loading}
          onClick={onPrev}
        >
          Previous
        </Button>
      )}
      <Button
        positive
        floated="right"
        loading={loading}
        disabled={loading || (step === 0 && !isAgree)}
        onClick={onNext}
      >
        {step === 3 ? 'Save' : 'Next'}
      </Button>
      {renderUserAgreement()}
    </div>
  )
}

export default RegisterActions
