import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import AcceptModal from '../../Modals/AcceptModal'
import DeclineModal from '../../Modals/DeclineModal'
import ReferralModal from '../../Modals/ReferralModal'

const Pending = ({ project, job }) => (
  <>
    <div className="ui two buttons">
      <AcceptModal project={project} job={job}>
        <Button basic color="green">
          Accept
        </Button>
      </AcceptModal>
      <DeclineModal project={project}>
        <Button basic color="red">
          Decline
        </Button>
      </DeclineModal>
    </div>
    {project.referable && (
      <Popup
        content="Share this project"
        position="top left"
        trigger={
          <ReferralModal project={project}>
            <Button
              circular
              size="small"
              style={{
                float: 'right',
                marginTop: '10px',
              }}
              icon="paper plane"
            />
          </ReferralModal>
        }
      />
    )}
  </>
)

export default Pending
