import React from 'react'
import { Header, Icon, Button } from 'semantic-ui-react'
import AddToCalendarContext, {
  AddToCalendarProvider,
} from '../../contexts/AddToCalendarContext'
import Alert from '../Alert'

const NextStepsContent = ({ type, job }) => {
  const NEXT_STEPS = [
    {
      icon: 'dollar',
      title: 'Set up Payment',
      message: `Head over to the  <a href=${
        window.location.origin
      }${'/payment'} target='_blank'>Payment page</a>  to learn more about the steps you need to take to set up direct deposits. This step can be completed prior to your engagement for the most seamless payment experience.`,
    },
    {
      icon: 'calendar check outline',
      title: 'Complete your Engagement',
      message:
        type === 'Interview'
          ? `Look out for an email with a calendar invite. You will be also be notified if the interviewer makes any changes to the event. Use the Reschedule option from your <a href=${
              window.location.origin
            }${'/projects/active'} target='_blank'>Project dashboard</a> if you ever need to make changes to the meeting date and time. Make sure to add the event to your calendar!`
          : `Head over to the <a href=${
              window.location.origin
            }${'/projects/pending'} target='_blank'> Pending Project page</a> to complete the survey as soon as possible.`,
    },

    {
      icon: 'address card outline',
      title: 'Enhance your Expert Profile',
      message: `Maximize your chance of getting invited to project opportunities by completing your expert profile in the <a href=${
        window.location.origin
      }${'/profile'} target='_blank'>Profile page</a>.`,
    },
  ]

  return (
    <React.Fragment>
      <Header size="medium">
        Congrats on getting your first Dexter engagement set up!
      </Header>
      <span>
        There is a few things you can do now to enhance your Dexter experience.
      </span>
      {NEXT_STEPS.map((step, index) => (
        <React.Fragment key={index}>
          <Header size="small">
            <div style={{ display: 'flex' }}>
              <Icon name={step.icon} color="grey" />
              {step.title}
            </div>
          </Header>
          <div dangerouslySetInnerHTML={{ __html: step.message }} />
          {step.title === 'Complete your Engagement' && type === 'Interview' && (
            <div id="add-calendar-container">
              <AddToCalendarProvider>
                <AddToCalendarContext.Consumer>
                  {({ handleAddToCalendar }) => (
                    <Button
                      id="add-calendar-btn"
                      onClick={() => handleAddToCalendar(job)}
                    >
                      <Button.Content>
                        <Icon name="calendar plus outline" size="large" />
                      </Button.Content>
                      <Button.Content>Add to calendar</Button.Content>
                    </Button>
                  )}
                </AddToCalendarContext.Consumer>
              </AddToCalendarProvider>
            </div>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
const NextStepsModal = ({ type, job, onClose }) => {
  return (
    <Alert
      title="What is Next?"
      content={<NextStepsContent type={type} job={job} />}
      onClose={onClose}
    />
  )
}

export default NextStepsModal
