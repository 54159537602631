import React, { useState, useEffect, useContext } from 'react'
import {
  Route,
  Switch,
  Link,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Grid, Menu, Label, Message } from 'semantic-ui-react'
import ProjectList from '../components/Projects/ProjectList'
import DataContext from '../contexts/DataContext'
import { ScheduleProvider } from '../contexts/ScheduleContext'
import NextStepsModal from '../components/Modals/NextStepsModal'
import PreinterviewModal from '../components/Modals/PreinterviewModal'

const Projects = () => {
  const history = useHistory()
  const location = useLocation()
  const { type, job } = location.state || {}
  const match = useRouteMatch('/projects/:page')
  const { advisor, activeProjects, pendingProjects, pastProjects } =
    useContext(DataContext)

  const [activeItem, setActiveItem] = useState('')
  const [showNextStepsModal, setShowNextStepsModal] = useState(
    location.state?.showNextStepsModal || false
  )
  const [showPreInterviewModal, setShowPreInterviewModal] = useState(
    location.state?.showPreInterviewModal && !showNextStepsModal
  )

  useEffect(() => {
    setActiveItem(match?.params.page || 'pending')
  }, [match])

  const handleItemClick = (e, { name }) => {
    setActiveItem(name)
    history.push(name === 'pending' ? `/projects` : `/projects/${name}`)
  }

  return (
    <>
      {showNextStepsModal && (
        <NextStepsModal
          type={type}
          job={job}
          onClose={() => {
            if (location.state?.showPreInterviewModal) {
              setShowPreInterviewModal(true)
            }
            setShowNextStepsModal(false)
          }}
        />
      )}
      {showPreInterviewModal && (
        <PreinterviewModal
          project={job.project}
          onClose={() => setShowPreInterviewModal(false)}
        />
      )}
      <Grid container stackable>
        <Grid.Row>
          <Grid.Column>
            <div id="project-section">
              <Menu tabular>
                <Menu.Item
                  name="active"
                  active={activeItem === 'active'}
                  onClick={handleItemClick}
                >
                  Active
                  <Label circular color="blue">
                    {activeProjects.length}
                  </Label>
                </Menu.Item>
                <Menu.Item
                  name="pending"
                  active={activeItem === 'pending'}
                  onClick={handleItemClick}
                >
                  Pending
                  <Label circular color="blue">
                    {pendingProjects.length}
                  </Label>
                </Menu.Item>
                <Menu.Item
                  name="past"
                  active={activeItem === 'past'}
                  onClick={handleItemClick}
                >
                  Past
                  <Label circular color="blue">
                    {pastProjects.length}
                  </Label>
                </Menu.Item>
              </Menu>
              <ScheduleProvider>
                <Switch>
                  <Route
                    path="/projects/active"
                    render={() => (
                      <ProjectList
                        category="active"
                        projects={activeProjects}
                      />
                    )}
                  />
                  <Route
                    path="/projects/past"
                    render={() => (
                      <>
                        {pastProjects.length !== 0 && (
                          <Message
                            className={
                              advisor.expert.stripe_account
                                ? 'success'
                                : 'warning'
                            }
                            header={
                              advisor.expert.stripe_account
                                ? 'You are connected to Stripe!'
                                : 'You are not connected to Stripe!'
                            }
                            content={
                              advisor.expert.stripe_account ? (
                                'If you recently completed a project, it may take a few days before the funds appear in your account'
                              ) : (
                                <div>
                                  Go to{' '}
                                  <Link
                                    to="/profile/bank"
                                    className="payment-link"
                                  >
                                    payment settings
                                  </Link>{' '}
                                  to set up payments
                                </div>
                              )
                            }
                          />
                        )}
                        <ProjectList category="past" projects={pastProjects} />
                      </>
                    )}
                  />
                  <Route
                    path="/projects"
                    render={() => (
                      <ProjectList
                        category="pending"
                        projects={pendingProjects}
                      />
                    )}
                  />
                </Switch>
              </ScheduleProvider>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default Projects
