import { gql } from '@apollo/client'
import { ORGANIZATION_MODEL } from './modelFields'

export const AUTO_COMPLETE_QUERY = gql`
  query Autocomplete($context: String, $query: String) {
    autocomplete(context: $context, query: $query) {
      value
      display
      organization {
        ${ORGANIZATION_MODEL}
      }
    }
  }
`

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      ${ORGANIZATION_MODEL}
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $organizationId: ID!
    $organization: OrganizationInput!
  ) {
    updateOrganization(
      organizationId: $organizationId
      organization: $organization
    ) {
      ${ORGANIZATION_MODEL}
    }
  }
`
