import React, { useContext } from 'react'
import { Message, Icon } from 'semantic-ui-react'
import moment from 'moment-timezone'
import MeetingModal from '../../../Modals/MeetingModal'
import DataContext from '../../../../contexts/DataContext'
import StartSurveyButton from './StartSurveyButton'

const Interview = ({ project, job }) => {
  const { advisor } = useContext(DataContext)
  const meetingAt = moment.tz(job.meetingAt, advisor.timeZone).format('lll z')
  return (
    <div>
      <span>
        {`The meeting has been successfully scheduled for ${meetingAt}. `}
      </span>
      <div className="active-zoom">
        <Icon name="video camera" />
        {'View '}
        <MeetingModal job={job}>
          <a href="# " className="active-link">
            dial-in instructions
          </a>
        </MeetingModal>
      </div>
      {project.preInterviewLink && (
        <div>
          <Icon name="linkify" />
          {'Check out the '}
          <a
            href={project.preInterviewLink}
            className="active-link"
            target="_blank"
            rel="noreferrer"
          >
            pre-interview material
          </a>
          {' before the meeting'}
        </div>
      )}
    </div>
  )
}

const Survey = ({ project, job }) => (
  <div>
    <p>{`You have accepted to participate in ${project.title} `}</p>
    <StartSurveyButton
      surveyLink={project.surveyLink || job.qualtricsContact?.surveyLink}
    />
    <p style={{ marginTop: '1rem' }}>
      Note: If you have already completed the survey, please allow some time for
      the project status to update.
    </p>
  </div>
)

const SelfServiceMessage = ({ project, job }) => {
  return (
    <Message
      positive
      attached
      header="Success"
      content={
        project.type === 'Interview' ? (
          <Interview project={project} job={job} />
        ) : (
          <Survey project={project} job={job} />
        )
      }
    />
  )
}

export default SelfServiceMessage
