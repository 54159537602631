import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal, Button, Input } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import { getDeclineNoticeEmail } from '../Emails/DeclineNoticeEmail'
import { SEND_EMAIL_MUTATION } from '../../graphql/sendgrid'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import DataContext from '../../contexts/DataContext'

const DeclineModal = ({ project, children }) => {
  const { advisor } = useContext(DataContext)

  const [open, setOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [showInput, setShowInput] = useState(false)
  const [name] = useState('')
  const [email] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(false)

  const [declineEmail] = useMutation(SEND_EMAIL_MUTATION)
  const [declineProject, { loading }] = useMutation(UPDATE_JOB_MUTATION, {
    onCompleted: () => {
      MixpanelTracker.trackDeclineInvite(project)
      alert('Successully declined the project')
    },
  })

  const onSubmit = () => {
    declineProject({
      variables: {
        userId: advisor.id,
        projectId: project.id,
        job: {
          decline_feedback: feedback,
          invite_status: 'Invited',
          response_status: 'Declined',
          overall_status: 'Declined',
          responseAt: new Date(),
        },
      },
      update: () => {
        //send email
        declineEmail({
          variables: {
            input: {
              to: {
                email: project.owners[0].email,
              },
              from: {
                email: 'help@dexterexperts.com',
                name: 'Dexter',
              },
              subject: `Dexter | ${project.title} An expert has declined your request!`,
              text: 'An expert has declined your request',
              html: getDeclineNoticeEmail(
                `${advisor.firstName} ${advisor.lastName}`,
                project.title,
                name,
                email
              ),
            },
          },
        })
      },
    })
  }

  const setAndConfirm = (reason) => {
    setFeedback(reason)
    setIsConfirmed(true)
  }

  const feedbackList = (
    <>
      <ul className="reason-list">
        <li
          onClick={() => setAndConfirm('This project is not a good fit for me')}
        >
          This project is not a good fit for me
        </li>
        <li
          onClick={() =>
            setAndConfirm('I am not available to participate in this project')
          }
        >
          I am not available to participate in this project
        </li>
        <li onClick={() => setAndConfirm('Personal reasons')}>
          Personal reasons
        </li>
        <li onClick={() => setShowInput(true)}>Other</li>
      </ul>
      {showInput ? (
        <Input
          placeholder="Enter your other feedback"
          fluid
          onChange={(e, { value }) => setFeedback(value)}
        />
      ) : null}
    </>
  )

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
    >
      <Modal.Header>
        You&apos;re declining to participate in {project.title}
      </Modal.Header>
      <Modal.Content>
        <p>
          {isConfirmed
            ? 'Got it! This project will be declined when you hit submit.'
            : `
              Can you tell us why you do not wish to
              participate in this project ? Your feedback will
              help us improve our algorithm for making better
              suggestions for you in the future.`}
        </p>
        {!isConfirmed && feedbackList}
      </Modal.Content>
      <Modal.Actions>
        {isConfirmed ? (
          <Button
            primary
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
          >
            SUBMIT
          </Button>
        ) : (
          <Button primary onClick={() => setIsConfirmed(true)}>
            Next
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default DeclineModal
