import React from 'react'
import { Step, Icon, Grid, Header } from 'semantic-ui-react'

const STEPS = [
  {
    name: 'Basics',
    title: 'Welcome to Dexter!',
    subTitle: "Let's start your profile",
  },
  {
    name: 'Professional',
    title: 'Tell us a bit about your current role',
    subTitle: null,
  },
  {
    name: 'Expertise',
    title: "Great! Let's now add tags to describe your expertise!",
    subTitle:
      'Your profile helps us match you to relevant project opportunities',
  },
  {
    name: 'EEO Information',
    title:
      'U.S Equal Employment Opportunity Information (Completion is voluntary)',
    subTitle: `
      We invite all individuals to voluntarily report their gender, age,
      education, race/ethnicity, and primary lanage. We collect this
      information to help ensure the assessment is in compliance with
      non-discrimination laws and do not have an adverse impact on any
      particular race/ethnicity or gender. This information will remain
      confidential and will not be used for any purposes other than those
      stated above.
    `,
  },
  {
    name: 'Done',
    title: null,
    subTitle: null,
  },
]

const StepStructure = ({ step }) => (
  <Grid container>
    <Grid.Row>
      <Grid.Column>
        <Step.Group stackable="tablet" size="mini">
          {STEPS.map((s, i) => (
            <Step
              key={s.name}
              active={i === step}
              completed={i < step}
              disabled={i > step}
            >
              {i < step && <Icon name="check" />}
              <Step.Content>
                <Step.Title>{s.name}</Step.Title>
              </Step.Content>
            </Step>
          ))}
        </Step.Group>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
        <Grid.Row>
          {STEPS[step].title && (
            <Header as="h1" className="greeting" style={{ marginBottom: 0 }}>
              {STEPS[step].title}
            </Header>
          )}
        </Grid.Row>
        <Grid.Row>
          {STEPS[step].subTitle && (
            <Header as="h3" className="sub-greeting">
              {STEPS[step].subTitle}
            </Header>
          )}
          {step === 0 && (
            <div style={{ float: 'right' }}>
              <small className="require-indication">
                <span className="require-star">*</span>
                {` indicates required fields`}
              </small>
            </div>
          )}
        </Grid.Row>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default StepStructure
