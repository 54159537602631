import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Image, Button, Header } from 'semantic-ui-react'

const NotFoundPage = ({ message }) => {
  const history = useHistory()
  return (
    <Grid container stackable className="not-found">
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Image
            src={`${process.env.REACT_APP_AWS_S3_URL}/page_not_found.svg`}
            size="medium"
            centered
          />
          <Header as="h2">The page you are looking for was not found.</Header>
          {message && <Header as="h5">{message}</Header>}
          <Button primary onClick={() => history.replace('/')}>
            Back to Home
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default NotFoundPage
