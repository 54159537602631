import React, { useState, useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import { getAcceptEmail } from '../Emails/AcceptEmail'
import { getCompleteSurveyEmail } from '../Emails/CompleteSurveyEmail'
import { SEND_EMAIL_MUTATION } from '../../graphql/sendgrid'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import DataContext from '../../contexts/DataContext'
import ScheduleContext from '../../contexts/ScheduleContext'
import QualificationQuestions from '../QualificationQuestions/'

// TODO: Remove interview part and rename this component to just "Survey" after fully merged DBC as a client
const FullServiceOrSurvey = ({ project, job, handleClose }) => {
  const history = useHistory()
  const { advisor } = useContext(DataContext)
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
  const [acceptProject, { loading }] = useMutation(UPDATE_JOB_MUTATION, {
    onCompleted: () => history.replace('/projects/active'),
  })
  const onAccept = () => {
    const jobInput = {
      response_status: 'Accepted',
      overall_status: 'Scheduled',
      responseAt: new Date(),
    }
    if (job.qualificationResponses.length > 0) {
      jobInput.qualificationResponses = job.qualificationResponses
    }
    acceptProject({
      variables: {
        userId: advisor.id,
        projectId: project.id,
        job: jobInput,
      },
      update: () => {
        MixpanelTracker.trackAcceptInvite(project)
        if (project.service_type === 'Full-Service') {
          if (project.type === 'Interview') {
            if (project.calendly_link) {
              window.open(project.calendly_link, '_blank')
              sendEmail({
                variables: {
                  input: {
                    to: { email: advisor.email, name: advisor.email },
                    from: { email: project.owners[0].email, name: 'Dexter' },
                    subject: `Dexter: Schedule a time for ${project.title}`,
                    text: 'Project accepted and Schedule the time',
                    html: getAcceptEmail(
                      advisor.firstName,
                      project.title,
                      project.calendly_link,
                      project.owners[0].email
                    ),
                  },
                },
              })
            }
          } else {
            // TODO: Remove sending email from frontend after fully migrate DBC
            sendEmail({
              variables: {
                input: {
                  to: { email: advisor.email, name: advisor.email },
                  from: { email: project.owners[0].email, name: 'Dexter' },
                  subject: 'Project Confirmed! | Complete Your Survey',
                  text: 'Project Confirmed and Complete your survey',
                  html: getCompleteSurveyEmail(
                    advisor.firstName,
                    project.title,
                    project.surveyLink
                  ),
                },
              },
            })
          }
        }
      },
    })
  }

  return (
    <>
      <Modal.Header>Do you wish to accept {project.title}?</Modal.Header>
      <Modal.Content>
        Click Accept below to proceed. Once you accept,{' '}
        {project.type === 'Interview'
          ? 'you will be directed to a scheduling page to book a time for your meeting.'
          : 'a link to the survey will become available in the project card.'}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          primary
          loading={loading}
          disabled={loading}
          style={{ margin: 'auto', marginBottom: '10px' }}
          onClick={onAccept}
        >
          Accept
        </Button>
      </Modal.Actions>
    </>
  )
}

const SelfService = ({ project, job, handleClose }) => {
  const { setScheduler } = useContext(ScheduleContext)
  const [showSurveyModal, setShowSurveyModal] = useState(false)
  const qualificationResponses = useRef([])

  const setQualificationResponses = (newResponse) => {
    qualificationResponses.current = newResponse
  }

  const onNext = () => {
    if (project.type === 'Interview') {
      handleClose()
      setScheduler({
        ...job,
        qualificationResponses: qualificationResponses.current,
      })
    } else {
      setShowSurveyModal(true)
    }
  }

  useEffect(() => {
    if (
      project.qualificationQuestions.length === 0 ||
      job.qualificationResponses.length > 0
    ) {
      if (project.type === 'Interview') {
        handleClose()
        setScheduler(job)
      } else {
        setShowSurveyModal(true)
      }
    }
  }, [job, project, setScheduler, handleClose])

  return (
    <>
      {showSurveyModal ? (
        <FullServiceOrSurvey
          project={project}
          job={{
            ...job,
            qualificationResponses: qualificationResponses.current,
          }}
          onClose={handleClose}
        />
      ) : (
        <>
          <Modal.Header>{project.title}</Modal.Header>
          <QualificationQuestions
            project={project}
            updateQualificationResponses={setQualificationResponses}
            onSubmit={onNext}
          />
        </>
      )}
    </>
  )
}

const AcceptModal = ({ project, job, children }) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)
  // TODO: Remove full service after fully merged DBC as a client
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      trigger={children}
    >
      {project.service_type === 'Full-Service' ? (
        <FullServiceOrSurvey
          project={project}
          job={job}
          handleClose={handleClose}
        />
      ) : (
        <SelfService project={project} job={job} handleClose={handleClose} />
      )}
    </Modal>
  )
}

export default AcceptModal
