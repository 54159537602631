import React, { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { Loader, Header, Button, Grid, Message } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'

import SchedulerContent from '../Schedule/Scheduler/SchedulerContent'
import { PROJECT_CALENDAR } from '../../graphql/project'

const PublicProjectSchedule = ({
  projectId,
  title,
  meetingAt,
  setMeetingTime,
  handleNext,
  message,
}) => {
  const { loading, error, data } = useQuery(PROJECT_CALENDAR, {
    variables: { id: projectId },
  })

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectSchedule()
  }, [])

  if (loading) return <Loader active inline />
  if (error) return null

  return (
    <div id="public-landing-schedule">
      <Header size="medium">{`Select a meeting time for ${title}`}</Header>
      <div id="scheduler">
        {message && <Message content={message} negative />}
        <SchedulerContent
          selectedTime={meetingAt}
          setSelectedTime={setMeetingTime}
          project={data.project}
        />
      </div>

      <Grid stackable>
        <Grid.Column width={11} />
        <Grid.Column width={5}>
          <div id="public-project-btn">
            <Button primary onClick={handleNext}>
              Next
            </Button>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default PublicProjectSchedule
