import React, { createContext } from 'react'
import { useQuery } from '@apollo/client'
import { mixpanelClient } from '../utils/MixpanelTracker'
import Loading from '../components/Loading'
import { LOAD_ME_QUERY } from '../graphql/user'
import {
  getBalance,
  getActiveProjects,
  getPendingProjects,
  getPastProjects,
} from '../utils/helpers'

const DataContext = createContext({ advisor: null })
export const DataProvider = ({ children }) => {
  const { loading, data } = useQuery(LOAD_ME_QUERY)
  if (loading) return <Loading />

  mixpanelClient.identify(data.me.fullName || data.me.email)

  return (
    <DataContext.Provider
      value={{
        advisor: data.me,
        balance: getBalance(data.me.jobs),
        activeProjects: getActiveProjects(data.me.jobs),
        pendingProjects: getPendingProjects(data.me.jobs),
        pastProjects: getPastProjects(data.me.jobs),
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataContext
