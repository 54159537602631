import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

const Alert = ({ title, content, onClose }) => (
  <Modal closeIcon open onClose={onClose}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Content>{content}</Modal.Content>
    <Modal.Actions>
      <Button positive onClick={onClose}>
        OK
      </Button>
    </Modal.Actions>
  </Modal>
)

export default Alert
