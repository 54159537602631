import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { Dropdown } from 'semantic-ui-react'
import DataContext from '../../contexts/DataContext'
import { UPDATE_USER, LOAD_ME_QUERY } from '../../graphql/user'
import { PROJECT_CALENDAR } from '../../graphql/project'

const TimeZone = ({ project = {} }) => {
  const { advisor } = useContext(DataContext)
  const [timeZone, setTimeZone] = useState(advisor.timeZone || '')
  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: () => {
      const queries = [{ query: LOAD_ME_QUERY }]
      if (project.id) {
        queries.push({
          query: PROJECT_CALENDAR,
          variables: { id: project.id },
        })
      }
      return queries
    },
    awaitRefetchQueries: true,
  })

  // Get a list of valid timezones in different cities for each country
  const timezonesFromCountries = [
    ...new Set(
      moment.tz
        .countries()
        .map((country) => moment.tz.zonesForCountry(country))
        .flat(1)
    ),
  ]

  const TZ_OPTIONS = timezonesFromCountries.map((timezone) => {
    const currentTime = moment.tz(timezone).format('LT')
    const offset = moment
      .tz(timezone)
      .format('z, Z')
      .replace(/\w+,/g, '')
      .replace(/\s+/g, '')
      .replace(/\+{2}/g, '+')
    return {
      key: timezone,
      value: timezone,
      offset,
      text: `(GMT ${offset}) ${timezone} (${currentTime})`,
    }
  })

  TZ_OPTIONS.sort((a, b) => (a.offset > b.offset ? 1 : -1))
  const updateTimeZone = (value) => {
    setTimeZone(value)
    updateUser({ variables: { input: { timeZone: value } } })
  }

  return (
    <Dropdown
      placeholder="Select your timezone"
      search
      loading={loading}
      disabled={loading}
      selection
      value={timeZone}
      options={TZ_OPTIONS}
      onChange={(e, { value }) => updateTimeZone(value)}
    />
  )
}

export default TimeZone
