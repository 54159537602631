import React from 'react'
import { List } from 'semantic-ui-react'

const CustomTooltip = ({ active, payload, label }) => {
  if (!active) {
    return null
  }

  const balance = payload.reduce((sum, item) => sum + item.value, 0)

  return (
    <div className="custom-tooltip">
      <h4>{label}</h4>
      <div className="content">
        <List>
          {payload.map((obj) => (
            <List.Item key={obj.dataKey}>
              {`${obj.dataKey}: $${obj.value}`}
            </List.Item>
          ))}
        </List>
        <p>Total earned: ${balance}</p>
      </div>
    </div>
  )
}

export default CustomTooltip
