import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { List, Input, Button, Popup, Placeholder } from 'semantic-ui-react'
import { LOAD_MEETING_INFO_QUERY } from '../../graphql/schedule'
import moment from 'moment-timezone'
import Alert from '../Alert'

const ScheduleConfirmationContent = ({ viewer, meeting }) => {
  const [copyPopup, setCopyPopup] = useState('Copy link')
  const onCopy = () => {
    const copyText = document.getElementById('zoom-link')
    copyText.select()
    // For mobile devices
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setCopyPopup('Copied !')
  }

  return (
    <List id="schedule-confirmation">
      <List.Item>
        <List.Content>
          <List.Header>
            {`Your meeting has been scheduled for
              ${moment.tz(meeting.meetingAt, viewer.timeZone).format('lll z')}`}
          </List.Header>
          <List.Description>
            <p>
              Please dial into the Zoom meeting at the scheduled time by the
              following the dial-in instructions below. Note this information is
              also available in your Active Projects tab.
            </p>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header>Meeting information </List.Header>
          <List.Description>
            <pre>{meeting.meetingInvite}</pre>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <Input
          label={
            <Popup
              content={copyPopup}
              on="click"
              pinned
              trigger={
                <Button className="basic-btn" onClick={onCopy}>
                  Copy Link
                </Button>
              }
            />
          }
          labelPosition="right"
          readOnly
          id="zoom-link"
          value={meeting.meetingLink}
        />
      </List.Item>
    </List>
  )
}

const ScheduleConfirmation = ({ viewer, job, onClose }) => {
  const { data, error, loading } = useQuery(LOAD_MEETING_INFO_QUERY, {
    variables: { id: job.id },
  })
  if (loading)
    return (
      <Alert
        title="Fetching meeting info..."
        content={
          <Placeholder fluid>
            <Placeholder.Image rectangular />
          </Placeholder>
        }
        onClose={onClose}
      />
    )
  if (error)
    return (
      <Alert
        title="Failed to fetch meeting information"
        content="Something went wrong while trying to fetch meeting information. Please try again. If the issue persists, contact support@dexterexperts.com."
        onClose={onClose}
      />
    )
  return (
    <Alert
      title="Success"
      content={
        <ScheduleConfirmationContent viewer={viewer} meeting={data.job} />
      }
      onClose={onClose}
    />
  )
}
export default ScheduleConfirmation
