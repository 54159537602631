import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { COUNTRIES_TO_CODES, CODES_TO_COUNTRIES } from '../constants/countries'

const CoutryDropdown = ({ defaultCountry, setCountry }) => (
  <Dropdown
    placeholder="Select Country"
    fluid
    search
    selection
    value={COUNTRIES_TO_CODES[defaultCountry]}
    options={Object.values(COUNTRIES_TO_CODES).map((countryCode) => ({
      key: countryCode,
      value: countryCode,
      text: CODES_TO_COUNTRIES[countryCode],
      flag: countryCode,
    }))}
    onChange={(e, { value }) => setCountry(CODES_TO_COUNTRIES[value])}
  />
)

export default CoutryDropdown
