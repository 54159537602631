export const getReferEmailToAdmin = (
  expert_name,
  project_title,
  refer_name,
  refer_email,
  linkedin
) => `
<html>
	<head>
		<style type="text/css">
			body, p, div, pre {
				font-family: arial,helvetica,sans-serif;
				font-size: 14px;
			}
		</style>
	</head>
	<body>
<pre>
This is an automated email to notify you that ${expert_name} has referred ${refer_name} for ${project_title}. An invitation was email was sent to the following recipient:

Name: ${refer_name}
Email: ${refer_email}
Linkedin profile: ${linkedin !== '' ? linkedin : '(none)'}

Log in to Dexter:
https://dexteradmin.herokuapp.com
</pre>
	</body>
</htm>
`

export const getReferEmailToRecipient = (
  refer_name,
  expert_name,
  project_title,
  project_detail
) => `
<html>
	<head>
		<style type="text/css">
			body, p, div, pre {
		        font-family: arial,helvetica,sans-serif;
		        font-size: 14px;
		    }
	    </style>
	</head>
	<body>
		<div>
<pre>
Hi ${refer_name},

${expert_name} recommended that we get in touch with you regarding a current project we have. Would you be able to take a look at the project description below and let us know if this would be a good fit for you? 
</pre>
<h4>&nbsp;</h4>
<p>Project Topic: ${project_title}:</p>
<p>Project details: </p> 
${project_detail}

<h4>&nbsp;</h4>
<pre>
If you are interested in speaking to us on the above topic, kindly follow the link here (${process.env.REACT_APP_BASE_URL}) to complete next steps to join our in-house expert network platform, Dexter. Please feel free to reach out to us at help@dexterexperts.com if you have any questions or concerns. 

We look forward to seeing you on Dexter soon!

Your Dexter Team

Dexter
10203 Santa Monica Blvd Suite 400, Los Angeles, CA 90067
</pre>
		</div>
	</body>
</html>
`
