import { ApolloLink } from '@apollo/client'
import ObjectHelper from './ObjectHelper'

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = ObjectHelper.omitDeep(
      operation.variables,
      '__typename'
    )
  }
  return forward(operation)
})

export default cleanTypenameLink
