import React from 'react'
import FullServiceMessage from './FullServiceMessage'
import SelfServiceMessage from './SelfServiceMessage'

const Active = ({ project, job }) => {
  if (project.service_type === 'Full-Service') {
    return <FullServiceMessage project={project} />
  }
  return <SelfServiceMessage project={project} job={job} />
}

export default Active
