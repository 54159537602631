export const SUPPORTED_COUNTRIES = [
  {
    name: 'Albania',
    code: 'al',
    express: true,
  },
  {
    name: 'Argentina',
    code: 'ar',
    express: true,
  },
  {
    name: 'Armenia',
    code: 'am',
    express: true,
  },
  {
    name: 'Australia',
    code: 'au',
    express: true,
  },
  {
    name: 'Austria',
    code: 'at',
    express: true,
  },
  {
    name: 'Bahamas',
    code: 'bs',
    express: true,
  },
  {
    name: 'Bahrain',
    code: 'bh',
    express: true,
  },
  {
    name: 'Bolivia',
    code: 'bo',
    express: true,
  },
  {
    name: 'Belgium',
    code: 'be',
    express: true,
  },
  {
    name: 'Brazil',
    code: 'br',
    express: false,
  },
  {
    name: 'Bulgaria',
    code: 'bg',
    express: true,
  },
  {
    name: 'Cambodia',
    code: 'kh',
    express: true,
  },
  {
    name: 'Canada',
    code: 'ca',
    express: true,
  },
  {
    name: 'Chile',
    code: 'cl',
    express: true,
  },
  {
    name: 'Colombia',
    code: 'co',
    express: true,
  },
  {
    name: 'Costa Rica',
    code: 'cr',
    express: true,
  },
  {
    name: 'Czech Republic',
    code: 'cz',
    express: true,
  },
  {
    name: 'Cyprus',
    code: 'cy',
    express: true,
  },
  {
    name: 'Croatia',
    code: 'hr',
    express: true,
  },
  {
    name: 'Côte d’Ivoire',
    code: 'ci',
    express: true,
  },
  {
    name: 'Denmark',
    code: 'dk',
    express: true,
  },
  {
    name: 'Dominican Republic',
    code: 'do',
    express: true,
  },
  {
    name: 'Ecuador',
    code: 'ec',
    express: true,
  },
  {
    name: 'Egypt',
    code: 'eg',
    express: true,
  },
  {
    name: 'El Salvador',
    code: 'sv',
    express: true,
  },
  {
    name: 'Estonia',
    code: 'ee',
    express: true,
  },
  {
    name: 'Ethiopia',
    code: 'et',
    express: true,
  },
  {
    name: 'Finland',
    code: 'fi',
    express: true,
  },
  {
    name: 'France',
    code: 'fr',
    express: true,
  },
  {
    name: 'Gambia',
    code: 'gm',
    express: true,
  },
  {
    name: 'Germany',
    code: 'de',
    express: true,
  },
  {
    name: 'Gibraltar',
    code: 'gi',
    express: false,
  },
  {
    name: 'Ghana',
    code: 'gh',
    express: true,
  },
  {
    name: 'Greece',
    code: 'gr',
    express: true,
  },
  {
    name: 'Guatemala',
    code: 'gt',
    express: true,
  },
  {
    name: 'Guyana',
    code: 'gy',
    express: true,
  },
  {
    name: 'Hong Kong SAR China',
    code: 'hk',
    express: true,
  },
  {
    name: 'Hungary',
    code: 'hu',
    express: true,
  },
  {
    name: 'Ireland',
    code: 'ie',
    express: true,
  },
  {
    name: 'Italy',
    code: 'it',
    express: true,
  },
  {
    name: 'India',
    code: 'in',
    express: true,
  },
  {
    name: 'Indonesia',
    code: 'id',
    express: true,
  },
  {
    name: 'Iceland',
    code: 'is',
    express: true,
  },
  {
    name: 'Israel',
    code: 'il',
    express: true,
  },
  {
    name: 'Jamaica',
    code: 'jm',
    express: true,
  },
  {
    name: 'Japan',
    code: 'jp',
    express: true,
  },
  {
    name: 'Jordan',
    code: 'jo',
    express: true,
  },
  {
    name: 'Kenya',
    code: 'ke',
    express: true,
  },
  {
    name: 'Kuwait',
    code: 'kw',
    express: true,
  },
  {
    name: 'Latvia',
    code: 'lv',
    express: true,
  },
  {
    name: 'Lithuania',
    code: 'lt',
    express: true,
  },
  {
    name: 'Luxembourg',
    code: 'lu',
    express: true,
  },
  {
    name: 'Liechtenstein',
    code: 'li',
    express: true,
  },
  {
    name: 'Macao SAR China',
    code: 'mo',
    express: true,
  },
  {
    name: 'Madagascar',
    code: 'mg',
    express: true,
  },
  {
    name: 'Malta',
    code: 'mt',
    express: true,
  },
  {
    name: 'Mexico',
    code: 'mx',
    express: true,
  },
  {
    name: 'Moldova',
    code: 'md',
    express: true,
  },
  {
    name: 'Mongolia',
    code: 'mn',
    express: true,
  },
  {
    name: 'Malaysia',
    code: 'my',
    express: true,
  },
  {
    name: 'Mauritius',
    code: 'mu',
    express: true,
  },
  {
    name: 'Morocco',
    code: 'ma',
    express: true,
  },
  {
    name: 'Namibia',
    code: 'na',
    express: true,
  },
  {
    name: 'Netherlands',
    code: 'nl',
    express: true,
  },
  {
    name: 'Norway',
    code: 'no',
    express: true,
  },
  {
    name: 'New Zealand',
    code: 'nz',
    express: true,
  },
  {
    name: 'Nigeria',
    code: 'ng',
    express: true,
  },
  {
    name: 'Oman',
    code: 'om',
    express: true,
  },
  {
    name: 'Panama',
    code: 'pa',
    express: true,
  },
  {
    name: 'Paraguay',
    code: 'py',
    express: true,
  },
  {
    name: 'Philippines',
    code: 'ph',
    express: true,
  },
  {
    name: 'Portugal',
    code: 'pt',
    express: true,
  },
  {
    name: 'Poland',
    code: 'pl',
    express: true,
  },
  {
    name: 'Peru',
    code: 'pe',
    express: true,
  },
  {
    name: 'Qatar',
    code: 'qa',
    express: true,
  },
  {
    name: 'Romania',
    code: 'ro',
    express: true,
  },
  {
    name: 'Rwanda',
    code: 'rw',
    express: true,
  },
  {
    name: 'Spain',
    code: 'es',
    express: true,
  },
  {
    name: 'Slovakia',
    code: 'sk',
    express: true,
  },
  {
    name: 'Slovenia',
    code: 'si',
    express: true,
  },
  {
    name: 'Switzerland',
    code: 'ch',
    express: true,
  },
  {
    name: 'Sweden',
    code: 'se',
    express: true,
  },
  {
    name: 'Saudi Arabia',
    code: 'sa',
    express: true,
  },
  {
    name: 'Senegal',
    code: 'sn',
    express: true,
  },
  {
    name: 'Serbia',
    code: 'cs',
    express: true,
  },
  {
    name: 'Singapore',
    code: 'sg',
    express: true,
  },
  {
    name: 'South Africa',
    code: 'za',
    express: true,
  },
  {
    name: 'South Korea',
    code: 'kr',
    express: true,
  },
  {
    name: 'Sri Lanka',
    code: 'lk',
    express: true,
  },
  {
    name: 'Saint Lucia',
    code: 'lc',
    express: true,
  },
  {
    name: 'Taiwan',
    code: 'tw',
    express: true,
  },
  {
    name: 'Tanzania',
    code: 'tz',
    express: true,
  },
  {
    name: 'Thailand',
    code: 'th',
    express: true,
  },
  {
    name: 'Tunisia',
    code: 'tn',
    express: true,
  },
  {
    name: 'Trinidad & Tobago',
    code: 'tt',
    express: true,
  },
  {
    name: 'Turkey',
    code: 'tr',
    express: true,
  },
  {
    name: 'United Arab Emirates',
    code: 'ae',
    express: true,
  },
  {
    name: 'Uruguay',
    code: 'uy',
    express: true,
  },
  {
    name: 'United Kingdom',
    code: 'gb',
    express: true,
  },
  {
    name: 'United States',
    code: 'us',
    express: true,
  },
  {
    name: 'Uzbekistan',
    code: 'uz',
    express: true,
  },
  {
    name: 'Vietnam',
    code: 'vn',
    express: true,
  },
]

export const EXPRESS_COUNTRIES = SUPPORTED_COUNTRIES.filter(
  (country) => country.express === true
).map((elem) => elem.name)
