import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Grid, Button, Image } from 'semantic-ui-react'
import AuthContext from '../contexts/AuthContext'
const NavbarLink = ({ name, link }) => {
  const location = useLocation()

  return (
    <NavLink
      to={link}
      activeStyle={{ color: 'green' }}
      className={
        ['/register'].includes(location.pathname) ? 'disabled-link' : ''
      }
    >
      {name}
    </NavLink>
  )
}
const Navbar = () => {
  const { logout } = useContext(AuthContext)

  return (
    <Grid container stackable id="navbar">
      <Grid.Row>
        <Grid.Column width={8} textAlign="right" className="logo">
          <NavLink to="/">
            <Image
              src={`${process.env.REACT_APP_AWS_S3_URL}/Dexterlogo.png`}
              size="small"
            />
          </NavLink>
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle">
          <Grid stackable>
            <Grid.Column width={3} verticalAlign="middle">
              <NavbarLink name="Home" link="/dashboard" />
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <NavbarLink name="Projects" link="/projects" />
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <NavbarLink name="Payment" link="/payment" />
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <NavbarLink name="Profile" link="/profile" />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button basic color="red" size="small" onClick={logout}>
                Sign out
              </Button>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Navbar
