import React, { useState, useContext } from 'react'
import { Modal, Button, Header } from 'semantic-ui-react'
import moment from 'moment-timezone'
import DataContext from '../../contexts/DataContext'
import ScheduleContext from '../../contexts/ScheduleContext'

const RescheduleMeeting = ({ job, children }) => {
  const [open, setOpen] = useState(false)
  const { advisor } = useContext(DataContext)
  const { setScheduler } = useContext(ScheduleContext)
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      className="setting-modal-layout"
    >
      <Modal.Header>Reschedule Meeting</Modal.Header>
      <Modal.Content>
        <Header>
          {`Are you sure you want to reschedule "${job.project.title}"?`}
        </Header>
        <p>
          {`Reschedule will cancel your existing meeting scheduled for
            ${moment.tz(job.meetingAt, advisor.timeZone).format('lll z')}.
            Proceed to the next step to select a new date and time.
          `}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            setScheduler(job)
            setOpen(false)
          }}
        >
          Reschedule Meeting
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RescheduleMeeting
