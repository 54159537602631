import React from 'react'
import { Form } from 'semantic-ui-react'
import Choice from './Choice'

const Choices = ({ question, rawResponses, updateRawResponses }) => {
  const handleTextChange = (question, response) => {
    let temp
    const res = rawResponses
      .find((q) => q.question === question)
      .response.slice()
    const index = res.findIndex((el) => el.substring(0, 5) === 'Other')
    res[index] = `Other: ${response}`

    temp = rawResponses.map((q) =>
      q.question === question ? { ...q, response: res } : q
    )
    updateRawResponses(temp)
  }

  const addOtherOption = (answerChoices, otherIncluded) => {
    if (otherIncluded) {
      return answerChoices.concat('Other')
    }
    return answerChoices
  }
  return (
    <>
      {addOtherOption(question.answerChoices, question.otherIncluded).map(
        (choice) => (
          <Choice
            key={`${question.question}${choice}`}
            question={question}
            choice={choice}
            rawResponses={rawResponses}
            updateRawResponses={updateRawResponses}
          />
        )
      )}

      {rawResponses
        .find((i) => i.question === question.question)
        ?.response?.some((el) => el && el.substring(0, 5) === 'Other') && (
        <Form.Input
          fluid
          required
          placeholder="Please specify"
          name={question.question}
          value={rawResponses
            .find((i) => i.question === question.question)
            ?.response.find((el) => el.substring(0, 5) === 'Other')
            ?.substring(7)}
          onChange={(e, target) =>
            handleTextChange(question.question, target.value)
          }
        />
      )}
    </>
  )
}
export default Choices
