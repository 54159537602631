import debounce from 'lodash.debounce'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw, EditorState, ContentState } from 'draft-js'

/**
 *   Infinite scrolling implementation
 *
 *   @param setNumberOfCards - increase the display amount for cards
 */
export const onInfiniteScroll = (setNumberOfCards) => {
  window.onscroll = debounce(() => {
    const getDocHeight = () => {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      )
    }
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      getDocHeight()
    ) {
      setNumberOfCards()
    }
  }, 500)
}

export const parseDatabase = (database) => {
  switch (database) {
    case 'google':
      return 'google-oauth2'
    case 'linkedin':
      return 'linkedin'
    default:
      return 'Username-Password-Authentication'
  }
}

/**
 *    Transfers the EditorState format data into plain HTML
 *
 *    @param str - HTML in String format
 *    @return accepted editorState format data
 */
export const getHTMLFromEditorState = (str) => {
  try {
    const result = JSON.parse(str)
    return stateToHTML(convertFromRaw(result))
  } catch (err) {
    return str
  }
}

/**
 *    Transfers HTML format into EditorState format data
 *
 *    @param str - String of HTML
 *    @return accepted editorState format data
 */
export const getEditorStateFromHTML = (str) => {
  try {
    const result = JSON.parse(str)
    return EditorState.createWithContent(convertFromRaw(result))
  } catch (err) {
    return EditorState.createWithContent(ContentState.createFromText(str || ''))
  }
}

export const getBalance = (advisorJobs) => {
  if (!advisorJobs) return 0
  let balance = 0
  advisorJobs
    .filter((job) => job.overall_status === 'Paid')
    .forEach((job) => (balance += job.payment.amount))
  return balance
}

export const isArchieved = (project) => {
  if (!project) return false
  return project.status === 'Completed'
}

export const getActiveProjects = (advisorJobs) => {
  if (!advisorJobs) return []

  const array = advisorJobs.slice()

  array.sort((a, b) => b.responseAt - a.responseAt)

  return array
    .filter((job) => {
      return (
        !isArchieved(job.project) &&
        job.invite_status === 'Invited' &&
        job.response_status === 'Accepted' &&
        job.overall_status === 'Scheduled'
      )
    })
    .map((job) => job.project)
}

export const getPendingProjects = (advisorJobs) => {
  if (!advisorJobs) return []

  const array = advisorJobs.slice()

  array.sort((a, b) => b.invitedAt - a.invitedAt)

  return array
    .filter((job) => {
      return (
        !isArchieved(job.project) &&
        job.invite_status === 'Invited' &&
        (job.overall_status === 'Pending Scheduling' ||
          job.overall_status === 'Client Reschedule')
      )
    })
    .map((job) => job.project)
}

export const getPastProjects = (advisorJobs) => {
  if (!advisorJobs) return []

  const array = advisorJobs.slice()

  array.sort((a, b) => b.completedAt - a.completedAt)

  return array
    .filter((job) => {
      return (
        isArchieved(job.project) ||
        job.overall_status === 'Paid' ||
        job.overall_status === 'Completed' ||
        job.overall_status === 'Cancelled' ||
        job.overall_status === 'Declined' ||
        job.overall_status === 'No Show' ||
        job.overall_status === 'Expert No Show' ||
        job.overall_status === 'Client No Show' ||
        job.overall_status === 'Expired'
      )
    })
    .map((job) => job.project)
}
