import React from 'react'
import { useHistory } from 'react-router-dom'
import Alert from '../Alert'

const ExistingResponseAlert = ({ project, onClose }) => {
  const history = useHistory()
  return (
    <Alert
      title="It looks like you have previously responded to this project invitation"
      content={`You have previously responded to the invitation to ${project.title}. There is no further action required for this request for now. Please reach out to help@dexterexperts.com if you have any questions.`}
      onClose={() => {
        history.replace(
          `/projects/${project.type === 'Interview' ? 'active' : 'pending'}`
        )
        onClose()
      }}
    />
  )
}

export default ExistingResponseAlert
