import { gql } from '@apollo/client'
import { JOB_MODEL } from './modelFields'

export const LOAD_MEETING_INFO_QUERY = gql`
  query Job($id: String!) {
    job(id: $id) {
      id
      meetingAt
      meetingInvite
      meetingLink
    }
  }
`

export const LOAD_SINGLE_MEETING_QUERY = gql`
  query Job($id: String!) {
    job(id: $id) {
      id
      user {
        id
      }
      project {
        id
        title
        description
        preInterviewLink
      }
      invitedAt
      meetingAt
      meetingLink
      meetingInvite
      overall_status
      qualificationResponses {
        response
        question
      }
    }
  }
`

export const START_SURVEY = gql`
  mutation StartSurvey($jobId: ID!) {
    startSurvery(jobId: $jobId) {
      id
      user {
        id
        jobs {
          ${JOB_MODEL}
        }
      }
    }
  }
`

export const SCHEDULE_MEETING = gql`
  mutation ScheduleMeeting($jobId: ID!, $startTime: Date) {
    scheduleMeeting(jobId: $jobId, startTime: $startTime) {
      id
      meetingAt
      meetingLink
      meetingInvite
      project {
        title
        preInterviewLink
      }
    }
  }
`

export const RESCHEDULE_MEETING = gql`
  mutation RescheduleMeeting($jobId: ID!) {
    rescheduleMeeting(jobId: $jobId) {
      id
    }
  }
`
