import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import MixpanelTracker from '../../../utils/MixpanelTracker'
import moment from 'moment-timezone'
import { Rating } from 'semantic-ui-react'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import DataContext from '../../../contexts/DataContext'

const Past = ({ project, job }) => {
  const { advisor } = useContext(DataContext)
  const [projectRating] = useState(job.project_rating || 0)
  const [updateRating] = useMutation(UPDATE_JOB_MUTATION)

  const submitExperience = (e, { rating }) => {
    updateRating({
      variables: {
        userId: advisor.id,
        projectId: project.id,
        job: {
          project_rating: rating,
        },
      },

      update: () => {
        MixpanelTracker.trackRateProject(project, rating)
      },
    })
  }
  return (
    <>
      {['Completed', 'Paid'].includes(job.overall_status) && (
        <span
          style={{
            color: '#000',
            fontWeight: 'bold',
            paddingTop: '5px',
          }}
        >
          Rating:{' '}
          <Rating
            icon="star"
            defaultRating={projectRating}
            maxRating={5}
            onRate={submitExperience}
          />
        </span>
      )}
      {job.overall_status === 'Paid' && (
        <span style={{ float: 'right', paddingTop: '5px' }}>
          {`$${job.payment.amount} paid via ${job.payment.method} | ${moment(
            job.payment.createdAt
          ).format('LL')}`}
        </span>
      )}
    </>
  )
}

export default Past
