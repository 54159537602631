import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Image, Segment } from 'semantic-ui-react'

const Logobar = () => {
  return (
    <Grid>
      <Grid.Row>
        <Segment clearing attached="top">
          <Link
            to={{ pathname: 'https://www.dexterexperts.com' }}
            target="_blank"
          >
            <Image
              size="small"
              src={`${process.env.REACT_APP_AWS_S3_URL}/dexterlogo_trans.png`}
              style={{ padding: 15 }}
            />
          </Link>
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default Logobar
