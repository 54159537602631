import React, { useState } from 'react'
import Alert from '../components/Alert'
import SuccessConfirmation from '../components/SuccessConfirmation'

const MessageContext = React.createContext({ setAlertMsg: null })
export const MessageProvider = ({ children }) => {
  const [alertMsg, setAlertMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)

  return (
    <MessageContext.Provider value={{ setAlertMsg, setSuccessMsg }}>
      {alertMsg && (
        <Alert
          title={alertMsg.title}
          content={alertMsg.content}
          onClose={() => {
            if (alertMsg.onClose) {
              alertMsg.onClose()
            }
            setAlertMsg(null)
          }}
        />
      )}
      {successMsg && (
        <SuccessConfirmation
          title={successMsg.title}
          content={successMsg.content}
          onClose={() => {
            if (successMsg.onClose) {
              successMsg.onClose()
            }
            setSuccessMsg(null)
          }}
        />
      )}
      {children}
    </MessageContext.Provider>
  )
}

export default MessageContext
