import React, { useContext } from 'react'
import {
  Grid,
  Button,
  Header,
  Image,
  Segment,
  Icon,
  List,
} from 'semantic-ui-react'
import AuthContext from '../contexts/AuthContext'

const Landing = () => {
  const { loginWithRedirect } = useContext(AuthContext)

  return (
    <Grid id="landing">
      <Grid.Column>
        <Grid container>
          <Grid.Column>
            <Grid.Row>
              <a
                href="https://www.dexterexperts.com/faq"
                target="_blank"
                rel="noopener noreferrer"
                className="top-link"
              >
                <Icon name="question" />
                {`Questions about `}
                <span>Dexter</span>
                {' ?'}
              </a>
            </Grid.Row>
            <Grid.Row>
              <Segment clearing attached="top">
                <Image
                  size="small"
                  src={`${process.env.REACT_APP_AWS_S3_URL}/dexterlogo_trans.png`}
                />
              </Segment>
            </Grid.Row>
            <Grid.Row>
              <div
                className="side-image"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_AWS_S3_URL}/side-image.png")`,
                }}
              >
                <Grid>
                  <Grid.Row>
                    <Header as="h1" className="heading">
                      Welcome to Dexter
                    </Header>
                  </Grid.Row>
                  <Grid.Row>
                    <Header as="h2" className="footer-text">
                      Share Insights
                      <br />
                      Advance Precision Medicine
                      <br />
                      Monetize Your Expertise
                    </Header>
                    <Header as="h5" className="sub-footer-text">
                      Dexter provides an exclusive channel for life science
                      professionals and a diverse array of stakeholders playing
                      a role in driving innovation in precision medicine to
                      share their insights with industry leaders
                    </Header>
                  </Grid.Row>
                  <Grid.Row>
                    <Button
                      primary
                      size="large"
                      onClick={() => loginWithRedirect()}
                    >
                      Log in / Sign up
                    </Button>
                  </Grid.Row>
                  <Grid.Row>
                    <List>
                      <List.Item>
                        <Icon name="connectdevelop" />
                        {' Connect with industry innovators'}
                      </List.Item>
                      <List.Item>
                        <Icon name="gift" />
                        {` Get invited to projects that match your expertise`}
                      </List.Item>
                      <List.Item>
                        <Icon name="target" />
                        {' Track your payment in real time'}
                      </List.Item>
                    </List>
                  </Grid.Row>
                </Grid>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

export default Landing
