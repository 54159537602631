import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Message, Icon, Transition, Button } from 'semantic-ui-react'
import { LOAD_ME_QUERY, UPDATE_USER } from '../../graphql/user'
import { UPDATE_EXPERT } from '../../graphql/expert'
import ProfilePicture from '../Profile/ProfilePicture'
import General from '../Forms/General'
import Professional from '../Forms/Professional'
import Expertise from '../Forms/Expertise'
import EEO from '../Forms/EEO'
import DataContext from '../../contexts/DataContext'

const SaveMessage = () => (
  <Transition visible animation="scale" duration={500}>
    <Message success floating>
      <Message.Header>
        <Icon name="check" />
        Saved
      </Message.Header>
    </Message>
  </Transition>
)

const TagSuggestionMessage = () => (
  <Message className="blue-box" size="large">
    <Message.Content>
      <Icon name="cloud download" />
      Your profile has been updated with data extracted from your provided
      LinkedIn profile. Press &quot;Confirm Changes&quot; to save.
    </Message.Content>
  </Message>
)

const Personal = () => {
  const history = useHistory()
  const { advisor } = useContext(DataContext)

  const generalRef = useRef(null)
  const professionalRef = useRef(null)
  const expertisRef = useRef(null)
  const eeoRef = useRef(null)

  const [isSaved, setIsSaved] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [updateExpert, { loading }] = useMutation(UPDATE_EXPERT)
  const [updateUser] = useMutation(UPDATE_USER)

  const hasTagSuggestions = advisor.expert.tagSuggestions.length > 0

  useEffect(() => {
    if (hasTagSuggestions) {
      // Scroll to the tag section
      const tagSection = document.getElementById('expertise-section')
      if (tagSection) {
        tagSection.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [hasTagSuggestions])

  const showErrorMsg = (msg) => {
    window.scrollTo(0, 0)
    setErrorMsg(msg)
  }

  const submitChange = (e) => {
    e.preventDefault()

    const { firstName, lastName, salutation, phone, country } =
      generalRef.current.state

    const { linkedinProfile, title, organization, hourlyRate } =
      professionalRef.current.state

    const { description, functions, technologyTags, medicalSpecialties } =
      expertisRef.current.state

    const {
      gender,
      yearOfBirth,
      education,
      ethnicity,
      language,
      veteranStatus,
      disabilityStatus,
    } = eeoRef.current.state

    if (!firstName) {
      return showErrorMsg('Please fill out your first name.')
    }
    if (!lastName) {
      return showErrorMsg('Please fill out your last name.')
    }
    if (!country) {
      return showErrorMsg('Please select your country.')
    }
    if (!title) {
      return showErrorMsg('Please fill out your title.')
    }
    if (!organization) {
      return showErrorMsg('Please select your organization.')
    }
    if (!hourlyRate || parseInt(hourlyRate) === 0) {
      return showErrorMsg('Please fill out your hourly rate.')
    }

    updateExpert({
      variables: {
        input: {
          first_name: firstName,
          last_name: lastName,
          salutation,
          description,
          linkedin_profile: linkedinProfile,
          phone,
          hourlyRate: parseInt(hourlyRate),
          country,
          title,
          gender,
          yearOfBirth: parseInt(yearOfBirth),
          education,
          ethnicity,
          language,
          veteran_status: veteranStatus,
          disability_status: disabilityStatus,
          functions,
          technology_tags: technologyTags,
          medical_specialties: medicalSpecialties,
          organization,
        },
      },
      update: (cache, { data: { updateExpert } }) => {
        updateUser({
          variables: { input: { firstName, lastName } },
          update: (cache, { data: { updateUser } }) => {
            cache.writeQuery({
              query: LOAD_ME_QUERY,
              data: { me: updateUser },
            })
          },
        })
        showMessage()
        window.scrollTo(0, 0)
        if (!updateExpert.user.expert.stripe_account) history.push('/payment')
      },
    })
  }

  const showMessage = () => {
    setIsSaved(true)
    setTimeout(() => setIsSaved(false), 3000)
  }

  return (
    <div>
      {isSaved && <SaveMessage />}
      {hasTagSuggestions && <TagSuggestionMessage />}
      {errorMsg && <Message negative>{errorMsg}</Message>}
      <div className="form-section">
        <ProfilePicture />
        <div style={{ textAlign: 'right' }}>
          <small className="require-indication">
            <span className="require-star">*</span> indicates required fields
          </small>
        </div>
        <General ref={generalRef} />
        <Professional ref={professionalRef} />
        <Expertise ref={expertisRef} />
        <EEO ref={eeoRef} />
      </div>
      <Button
        primary
        className="profile-btn-section"
        floated="right"
        loading={loading}
        disabled={loading}
        onClick={submitChange}
      >
        {hasTagSuggestions
          ? 'Confirm Changes'
          : `Save${advisor.expert.stripe_account ? '' : ' and Continue'}`}
      </Button>
    </div>
  )
}

export default Personal
