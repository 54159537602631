class QualificationHelper {
  static validateResponses({ qualificationQuestions, qualificationResponses }) {
    const errors = []
    if (
      qualificationResponses.length !== qualificationQuestions.length ||
      qualificationResponses.some(
        (q) => !q.response || q.response.join('').length === 0
      )
    ) {
      errors.push('All questions must be answered.')
    }
    if (
      qualificationResponses
        .map((q) => q.response)
        .flat()
        .some((res) => res === 'Other' || res === 'Other:')
    ) {
      const invalidOtherResponse = qualificationResponses.find(
        (res) =>
          res.response.some((r) => r === 'Other:' || r === 'Other') &&
          qualificationQuestions.find((i) => i.question === res.question)
            .otherIncluded
      )
      if (invalidOtherResponse) {
        errors.push("Please provide additional details for the 'Other' option")
      }
    }
    return errors
  }
  static sanitizeResponses(qualificationResponses) {
    return qualificationResponses.map((res) => {
      const { question, response } = res
      const trimResponses = response.map((r) => {
        if (r.startsWith('Other') && r.substring(7).trim().length > 0) {
          return `Other: ${r.substring(7).trim()}`
        } else {
          return r.trim()
        }
      })
      return { question, response: trimResponses }
    })
  }
}

export default QualificationHelper
