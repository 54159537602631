import React from 'react'
import { Form, FormGroup, Header } from 'semantic-ui-react'
const Knockout = ({ question, rawResponses, updateRawResponses }) => {
  const OPTIONS = ['Yes', 'No']
  const handleSelect = (question, value) => {
    const index = rawResponses.findIndex((q) => q.question === question)
    let temp
    if (index === -1) {
      temp = rawResponses.concat({
        response: [value],
        question,
      })
    } else {
      temp = rawResponses.map((q) =>
        q.question === question ? { ...q, response: [value] } : q
      )
    }
    updateRawResponses(temp)
  }
  return (
    <div className="qualification-question">
      <Form.Field>
        <Header size="tiny">{question.question}</Header>
        <FormGroup grouped>
          {OPTIONS.map((option) => (
            <Form.Radio
              key={option}
              label={option}
              value={option}
              checked={
                rawResponses.find((res) => res.question === question.question)
                  ?.response[0] === option
              }
              onClick={(e, target) =>
                handleSelect(question.question, target.value)
              }
            />
          ))}
        </FormGroup>
      </Form.Field>
    </div>
  )
}
export default Knockout
