export const FUNCTIONS = [
  {
    header: 'Business',
    icon: 'suitcase',
    items: [
      'C-Suite',
      'VP',
      'Executive / Head',
      'Director',
      'Manager',
      'Consultant',
      'Analyst',
      'Founder',
      'Investor',
      'Partner',
    ],
  },
  {
    header: 'Research',
    icon: 'flask',
    items: [
      'Principal Investigator',
      'Senior Scientist',
      'Researcher',
      'Lab Director / Manager',
      'Lab Technician',
      'Scientist',
    ],
  },
  {
    header: 'Academic',
    icon: 'graduation cap',
    items: ['Professor', 'Assistant Professor', 'Post-doc'],
  },
  {
    header: 'Clinical',
    icon: 'stethoscope',
    items: [
      'Physician',
      'Non-Physician Clinician',
      "Physician's Assistant",
      'Nurse Navigator',
      'Nurse / Phlebotomist',
      'Case Manager',
      'Practice Administrator',
      'Medical Director',
    ],
  },
  {
    header: 'Digital / Technical',
    icon: 'desktop',
    items: [
      'IT specialist',
      'Bioinformatician',
      'Computational Biologist',
      'Statistician',
      'Engineer',
      'Regulatory Specialist',
      'Medical Reimbursment Specialist',
      'Software Engineer',
    ],
  },
]

export const TECHNOLOGY_TAGS = [
  {
    header: 'Sequencing',
    icon: 'dna',
    items: [
      'NGS',
      'CE/Sanger',
      'Single Cell Sequencing',
      'Single-Cell RNA-Seq',
      'Single-Cell ATAC-Seq',
      'Nanopore Sequencing',
      'Methylomics / Epigenetics',
      'Transcriptomics',
      'Spatial-Omics',
      'RNA-Seq',
      'Proteomics',
      'Metabolomics',
      'ctDNA / cfDNA',
    ],
  },
  {
    header: 'Primer/Oligos Assays',
    icon: 'flask',
    items: [
      'Standard PCR',
      'qPCR',
      'dPCR',
      'ddPCR',
      'Reverse Transcriptase-PCR',
      'Real Time-PCR',
      'Singleplex PCR',
      'Mulitplex PCR',
      'RNAi',
      'Microarray',
    ],
  },
  {
    header: 'IA/Cell Assays',
    icon: 'eye dropper',
    items: [
      'Antibodies',
      'FISH',
      'RNA ISH',
      'IHC',
      'Flow Cytometry',
      'ELISA',
      'Immunoblot / Immunofluorescence',
      'Antigen Test',
      'Antibody Test',
      'Phage Display',
      'Lateral Flow Assay',
      'Proximity Extension Assay (PEA)',
      'Circulating Tumor Cells (CTCs)',
      'Cell Culture',
      'Molecule Characterization',
      'Mass Spec',
      'Mass Cytometry',
      'High Performance Liquid Chromatography (HPLC)',
      'Gas chromatography (GC)',
      'Asymmetrical Flow-Field Flow Fractionation',
    ],
  },
  {
    header: 'Cell/Gene-Editing',
    icon: 'cut',
    items: [
      'CRISPR',
      'Transposases',
      'ZFN',
      'Exosomes',
      'Lipid Nanoparticles',
      'Viral Vectors',
      'Non-Viral Vector',
      'RNPs',
      'Base Editing',
      'Prime Editing',
    ],
  },
  {
    header: 'Imaging/Measurement',
    icon: 'expand',
    items: [
      'UV-Vis',
      'Microscopy',
      'Spectrophotometry',
      'Computerized microtomography (microCT)',
      'MRI',
      'CT Scan',
      'PET Scan',
      'Ultrasound',
      'Calorimetry',
      'High content imaging',
    ],
  },
  {
    header: 'Digital',
    icon: 'desktop',
    items: [
      'Alexa',
      'Google Assistant',
      'Siri',
      'C++',
      'Databases',
      'NLP',
      'AI',
      'Ontology',
      'EKG',
      'Health Accessories',
      'Digital pathology',
      'Electronic Health Records',
    ],
  },
  {
    header: 'Other',
    icon: 'tags',
    items: [
      'Animal studies',
      'High Throughput Screening',
      'Membrane Fluidity',
      'Protein Biomarker',
      'IGRA',
    ],
  },
]

export const MEDICAL_SPECIALTIES = [
  {
    header: null,
    items: [
      'Anesthesiology',
      'Autoimmune',
      'Dermatology',
      'Cardiology',
      'Aging Disease',
      'Allergy/Immunology',
      'COVID',
      'Arthritis',
      'Medical Oncology',
      'Internal Medicine',
      'Oncology - Lung',
      'Microbiology',
      'Oncology - Gyn/Uterine/Cervical/Ovarian',
      'Neurological',
      'Nutrition',
      'NIPT',
      'Geriatrics',
      'Hematology',
      'Oncology',
      'OB/GYN',
      'Oncology - Prostate',
      'Emergency Medicine',
      'Medical Genetics',
      'Oncology - Blood Disorder',
      'Oncology - CRC',
      'Endocrinology/Diabetes/Metabolism',
      'Oncology - Breast',
      'Oncology - Surgical',
      'Oncology - Radiation',
      'Endometriosis',
      'Genetic Disorder',
      'Oncology - General',
      'Family/General Practice',
      'GVHD',
      'Infectious Disease',
      'Ophthalmology',
      'Orthopedics',
      'Otolaryngology',
      'Pathology',
      'Pediatrics',
      'Physical Medicine & Rehab',
      'Plastic Surgey',
      'Primary Care',
      'Psychiatry',
      'Radiology',
      'Rare Disease',
      'Respiratory',
      'STIs / STDs',
      'Surgery',
      'Urology',
      'Virology',
      "Women's Health",
    ],
  },
]
