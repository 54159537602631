import React from 'react'
import { FormField, Radio, Checkbox } from 'semantic-ui-react'

const Choice = ({ question, choice, rawResponses, updateRawResponses }) => {
  const toggleCheckbox = (array, item) => {
    const index = array.findIndex((existingItem) => existingItem === item)

    if (index === -1) {
      return [...array, item]
    } else {
      return array.filter((_, i) => i !== index)
    }
  }
  const handleSelect = (questionObj, value) => {
    const { question, questionType } = questionObj
    let temp
    const index = rawResponses.findIndex((q) => q.question === question)
    if (index === -1) {
      temp = rawResponses.concat({
        response: [value],
        question,
      })
    } else if (
      value === 'Other' &&
      questionType === 'multipleChoice' &&
      rawResponses[index].response.find(
        (el) => el && el.substring(0, 5) === 'Other'
      )
    ) {
      return
    } else if (questionType === 'multipleChoice') {
      temp = rawResponses.map((q) =>
        q.question === question ? { ...q, response: [value] } : q
      )
    } else {
      let tempVal = value
      if (value === 'Other') {
        tempVal =
          rawResponses[index].response.find(
            (el) => el && el.substring(0, 5) === 'Other'
          ) ?? value
      }
      temp = rawResponses.map((q) =>
        q.question === question
          ? { ...q, response: toggleCheckbox(q.response, tempVal) }
          : q
      )
    }
    updateRawResponses(temp)
  }
  return (
    <FormField
      control={question.questionType === 'checkbox' ? Checkbox : Radio}
      label={choice}
      value={choice}
      checked={rawResponses
        .find((res) => res.question === question.question)
        ?.response.some(
          (item) =>
            item === choice ||
            (choice === 'Other' && item.substring(0, 6) === 'Other:')
        )}
      onClick={(e, target) => handleSelect(question, target.value)}
    />
  )
}

export default Choice
