import React from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'semantic-ui-react'

const Welcome = () => (
  <div className="welcome-page container">
    <br />
    <h1 style={{ color: 'black' }}>Success!</h1>
    <h3>Your account has been created</h3>
    <h4>You are now part of our Dexter community!</h4>

    <Image
      src={`${process.env.REACT_APP_AWS_S3_URL}/fogg-success-1.png`}
      alt="welcome"
    />
    <br />
    <Link to="/payment" className="btn btn-primary shadow">
      Set up payments
    </Link>
    <br />
    <br />
    <span>If you have recently completed a consultation with us,</span>
    <p>please make sure to complete this step to receive your honorarium.</p>
    <Link
      to="/dashboard"
      style={{ color: 'rgb(129, 135,150)' }}
      id="skip-for-now"
    >
      Skip for now
    </Link>
    <br />
    <br />
  </div>
)

export default Welcome
