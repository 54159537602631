import React from 'react'
import { Modal, Button, Header } from 'semantic-ui-react'
import TimeZone from './TimeZone'

const TimeZoneSelector = ({ onCancel, project, onNext }) => {
  return (
    <Modal closeIcon open onClose={onCancel}>
      <Modal.Header>{`Schedule meeting time for ${project.title}`}</Modal.Header>
      <Modal.Content>
        <p>Please select your available time slot to schedule a meeting</p>

        <Header as="h4">Please select your timezone</Header>
        <TimeZone project={project} />
        <br />
        <br />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => onNext()}>
          Next
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default TimeZoneSelector
