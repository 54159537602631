import React from 'react'
import { Grid, Header } from 'semantic-ui-react'
import BoxLink from './BoxLink'

const QuickLinks = () => (
  <Grid stackable className="quick-links">
    <Grid.Row className="title">
      <Header as="h1">Quick Links</Header>
    </Grid.Row>
    <Grid.Row columns={5} className="menu">
      <Grid.Column>
        <BoxLink
          to="/profile"
          title="View & Edit Profile"
          content="Completed your profile and get matched with relevant projects."
        />
      </Grid.Column>
      <Grid.Column>
        <BoxLink
          to="/payment"
          title="Payment Settings"
          content="Add your bank account and view your payment history."
        />
      </Grid.Column>
      <Grid.Column>
        <BoxLink
          to="/profile/account"
          title="Notification Settings"
          content="Choose where and how you receive notification from Dexter"
        />
      </Grid.Column>
      <Grid.Column>
        <BoxLink
          to="/profile/account"
          title="Password Settings"
          content="Edit your account and password settings here."
        />
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default QuickLinks
