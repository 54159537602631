import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import DataContext from '../../contexts/DataContext'
import ScheduleConfirmation from './ScheduleConfirmation'
import PreinterviewModal from '../Modals/PreinterviewModal'
import TimeZoneSelector from './TimeZoneSelector'
import Scheduler from './Scheduler'

const ScheduleModal = ({ job, onClose }) => {
  const history = useHistory()
  const { advisor } = useContext(DataContext)

  const [scheduleStep, setScheduleStep] = useState(0)

  const handleScheduleConfirmationClose = () => {
    if (job.project.preInterviewLink) {
      onNext()
    } else redirectAndClose()
  }

  const redirectAndClose = () => {
    history.replace('/projects/active')
    onClose()
  }
  const onNext = () => {
    setScheduleStep(scheduleStep + 1)
  }

  switch (scheduleStep) {
    case 1:
      return (
        <Scheduler
          onNext={onNext}
          advisor={advisor}
          job={job}
          onCancel={onClose}
        />
      )
    case 2:
      return (
        <ScheduleConfirmation
          viewer={advisor}
          job={job}
          onClose={handleScheduleConfirmationClose}
        />
      )
    case 3:
      return (
        <PreinterviewModal project={job.project} onClose={redirectAndClose} />
      )
    default:
      return (
        <TimeZoneSelector
          onCancel={onClose}
          project={job.project}
          onNext={onNext}
        />
      )
  }
}

export default ScheduleModal
