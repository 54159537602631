import { gql } from '@apollo/client'

export const CONNECT_STRIPE_MUTATION = gql`
  mutation ConnectExpertToStripe($code: String!) {
    connectExpertToStripe(code: $code) {
      success
      error
    }
  }
`

export const CREATE_STRIPE_LOGIN_LINK_MUTATION = gql`
  mutation CreateStripeLoginLink($stripe_account_id: ID!) {
    createStripeLoginLink(stripe_account_id: $stripe_account_id) {
      success
      uri
      error
    }
  }
`
export const DISCONNECT_STRIPE_MUTATION = gql`
  mutation DisconnectExpertFromStripe {
    disconnectExpertFromStripe {
      success
      error
    }
  }
`
