import { languages } from '../../utils/language'

export const EEOOptions = {
  gender: [
    {
      key: 'Female',
      text: 'Female',
      value: 'Female',
    },
    {
      key: 'Male',
      text: 'Male',
      value: 'Male',
    },
    {
      key: 'Non-binary',
      text: 'Non-binary',
      value: 'Non-binary',
    },
    {
      key: 'Decline To Self Identify',
      text: 'Decline To Self Identify',
      value: 'Decline To Self Identify',
    },
  ],
  yearOfBirth: [...Array(100)]
    .map((_, i) => new Date().getFullYear() - i)
    .map((year) => ({
      key: year,
      text: year,
      value: year,
    })),
  education: [
    {
      key: 'high school',
      text: 'High school graduate, diploma or the equivalent (for example: GED)',
      value: 'high school',
    },
    {
      key: 'Bachelor’s degree',
      text: 'Bachelor’s degree',
      value: 'Bachelor’s degree',
    },
    {
      key: 'Master’s degree',
      text: 'Master’s degree',
      value: 'Master’s degree',
    },
    {
      key: 'Ph.D. or higher',
      text: 'Ph.D. or higher',
      value: 'Ph.D. or higher',
    },
    {
      key: 'Professional Degree (e.g., MD, DDS, JD)',
      text: 'Professional Degree (e.g., MD, DDS, JD)',
      value: 'Professional Degree (e.g., MD, DDS, JD)',
    },
    {
      key: 'Associates Degree (e.g., AA, AS)',
      text: 'Associates Degree (e.g., AA, AS)',
      value: 'Associates Degree (e.g., AA, AS)',
    },
    {
      key: 'Some college, but no degree',
      text: 'Some college, but no degree',
      value: 'Some college, but no degree',
    },
    {
      key: 'Prefer Not to Answer',
      text: 'Prefer Not to Answer',
      value: 'Prefer Not to Answer',
    },
  ],
  ethnicity: [
    {
      key: 'American Indian or Alaskan Native',
      text: 'American Indian or Alaskan Native',
      value: 'American Indian or Alaskan Native',
    },
    {
      key: 'Asian',
      text: 'Asian',
      value: 'Asian',
    },
    {
      key: 'Black or African American',
      text: 'Black or African American',
      value: 'Black or African American',
    },
    {
      key: 'Hispanic or Latino',
      text: 'Hispanic or Latino',
      value: 'Hispanic or Latino',
    },
    {
      key: 'White',
      text: 'White',
      value: 'White',
    },
    {
      key: 'Native Hawaiian or Other Pacific Islander',
      text: 'Native Hawaiian or Other Pacific Islander',
      value: 'Native Hawaiian or Other Pacific Islander',
    },
    {
      key: 'Two or More Races',
      text: 'Two or More Races',
      value: 'Two or More Races',
    },
    {
      key: 'Decline To Self Identify',
      text: 'Decline To Self Identify',
      value: 'Decline To Self Identify',
    },
  ],
  language: languages.map((obj) => {
    return {
      key: obj.name,
      text: obj.name,
      value: obj.name,
    }
  }),
  veteran_status: [
    {
      key: 'I am not a protected veteran',
      text: 'I am not a protected veteran',
      value: 'I am not a protected veteran',
    },
    {
      key: 'I identify as one or more of the classfication of a protected veteran',
      text: 'I identify as one or more of the classfication of a protected veteran',
      value:
        'I identify as one or more of the classfication of a protected veteran',
    },
    {
      key: 'I do not wish to answer',
      text: 'I do not wish to answer',
      value: 'I do not wish to answer',
    },
  ],
  disability_status: [
    {
      key: 'Yes, I have a disability, or have a history/record of having a disability',
      text: 'Yes, I have a disability, or have a history/record of having a disability',
      value:
        'Yes, I have a disability, or have a history/record of having a disability',
    },
    {
      key: 'No, I do not have a disability, or a history/record of having a disability',
      text: 'No, I do not have a disability, or a history/record of having a disability',
      value:
        'No, I do not have a disability, or a history/record of having a disability',
    },
    {
      key: 'I do not wish to answer',
      text: 'I do not wish to answer',
      value: 'I do not wish to answer',
    },
  ],
}
