import React, { useContext } from 'react'
import { Icon, Dropdown } from 'semantic-ui-react'
import ReportNoShow from '../Modals/ReportNoShow'
import ScheduleContext from '../../contexts/ScheduleContext'
import AddToCalendarContext, {
  AddToCalendarProvider,
} from '../../contexts/AddToCalendarContext'

const ProjectSettings = ({ meeting }) => {
  const { setScheduler } = useContext(ScheduleContext)

  return (
    <Dropdown
      trigger={<Icon id="ellipsis-menu" name="ellipsis vertical" />}
      pointing="top right"
      id="project-settings"
    >
      <Dropdown.Menu>
        {meeting.meetingAt < new Date(new Date().getTime() - 5 * 60000) && (
          <ReportNoShow job={meeting}>
            <Dropdown.Item as="a">Report a no show</Dropdown.Item>
          </ReportNoShow>
        )}
        {meeting.meetingAt > new Date() && (
          <>
            <AddToCalendarProvider>
              <AddToCalendarContext.Consumer>
                {({ handleAddToCalendar }) => (
                  <Dropdown.Item
                    as="a"
                    onClick={() => handleAddToCalendar(meeting)}
                  >
                    Add to Calendar
                  </Dropdown.Item>
                )}
              </AddToCalendarContext.Consumer>
            </AddToCalendarProvider>
            <Dropdown.Item as="a" onClick={() => setScheduler(meeting)}>
              Reschedule the meeting
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProjectSettings
