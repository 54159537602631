import React from 'react'
import { Grid } from 'semantic-ui-react'
import Navbar from './Navbar'

const NavbarLayout = ({ children }) => (
  <Grid container stackable id="main">
    <Navbar />
    <div>{children}</div>
  </Grid>
)

export default NavbarLayout
