import React, { useRef, useEffect, useState } from 'react'

import { Header, Message, Button } from 'semantic-ui-react'
import moment from 'moment-timezone'
import General from '../Forms/General'
import Professional from '../Forms/Professional'

import PublicProjectInfo from './PublicProjectInfo'
import MixpanelTracker from '../../utils/MixpanelTracker'

const PublicProjectRegisterForm = ({
  meetingAt,
  timeZone,
  project,
  qualificationResponses,
  handleBack,
  handleSubmit,
  isExistingUser,
}) => {
  const [message, setMessage] = useState(null)
  const generalRef = useRef(null)
  const professionalRef = useRef(null)

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectRegisterForm()
  }, [])

  const onSchedule = () => {
    setMessage(null)

    if (!isExistingUser) {
      const { firstName, lastName, country } = generalRef.current.state
      const { title, organization } = professionalRef.current.state
      if (firstName === '') {
        return setMessage('Please fill out your first name.')
      }
      if (lastName === '') {
        return setMessage('Please fill out your last name.')
      }
      if (country === '') {
        return setMessage('Please select your country.')
      }
      if (title === '') {
        return setMessage('Please fill out your title.')
      }
      if (!organization) {
        return setMessage('Please select your organization.')
      }
      return handleSubmit(
        generalRef.current.state,
        professionalRef.current.state
      )
    }
    handleSubmit()
  }

  return (
    <div id="public-project-landing">
      <div className="content-block">
        Please review the details below and hit &quot;Submit&quot; to{' '}
        {project.type === 'Interview'
          ? ' schedule your meeting'
          : ' book your engagement'}
      </div>
      <div className="content-block">
        <Header size="medium">{project.title}</Header>
        {project.type === 'Interview' && (
          <Header size="small">
            {moment.tz(meetingAt, timeZone).format('llll z')}
          </Header>
        )}
        <PublicProjectInfo
          project={project}
          qualificationResponses={qualificationResponses}
        />
      </div>
      {!isExistingUser && (
        <div className="content-block">
          {message && (
            <Message error header="Empty fields detected!" content={message} />
          )}

          <Header sub>About you</Header>
          <div className="form">
            <span>
              Please provide the below information help us learn more about you
              before the engagement
            </span>
            <General
              isRegister={true}
              skipTimeZone={true}
              skipHeader={true}
              ref={generalRef}
            />
            <Professional
              skipHourlyRate={true}
              skipHeader={true}
              ref={professionalRef}
            />
          </div>
        </div>
      )}
      <div
        className={`public-project-form-btn ${
          project.type === 'Interview' ? 'double-btn' : 'single-btn'
        }`}
      >
        {project.type === 'Interview' && (
          <Button onClick={handleBack} id="back" icon="chevron left" />
        )}

        <Button primary onClick={onSchedule} id="schedule">
          Submit
        </Button>
      </div>
    </div>
  )
}

export default PublicProjectRegisterForm
