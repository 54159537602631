import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

const PublicProjectLayout = ({ children }) => {
  return (
    <div id="public-project-landing">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column computer={2} only={'computer'} />
          <Grid.Column computer={10}>{children}</Grid.Column>
          <Grid.Column computer={4}>
            <div id="contact">
              <Header sub>Questions?</Header>
              <span id="contact-text">
                Contact help@dexterexperts.com <br />
                for any questions
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default PublicProjectLayout
