import { gql } from '@apollo/client'
import { USER_MODEL } from './modelFields'

export const LOAD_ME_QUERY = gql`
  query User {
    me {
      ${USER_MODEL}
      jobs {
        meetingLink
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput) {
    updateUser(input: $input) {
      ${USER_MODEL}
    }
  }
`

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($email: String!) {
    updateEmail(email: $email) {
      id
    }
  }
`
