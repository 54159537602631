import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal, Form, Button } from 'semantic-ui-react'
import { ORG_SIZE, ORG_TYPES } from '../../constants/organization'
import { COUNTRIES_TO_CODES } from '../../constants/countries'
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from '../../graphql/organization'
import Autocomplete from './Autocomplete'
import MessageContext from '../../contexts/MessageContext'

const NewOrganizationModal = ({ defaultQuery, done, open, onClose }) => {
  const { setAlertMsg, setSuccessMsg } = useContext(MessageContext)
  const [name, setName] = useState('')
  const [organizationId, setOrganizationId] = useState(null)
  const [size, setSize] = useState('')
  const [type, setType] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [url, setUrl] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [formError, setFormError] = useState({})

  useEffect(() => {
    // Updates the search bar default value based on organization autocomplete query
    setName(defaultQuery)
  }, [defaultQuery])

  const [createOrganization, { loading: creating }] = useMutation(
    CREATE_ORGANIZATION,
    {
      update: (cache, { data: { createOrganization } }) => {
        cache.modify({
          fields: {
            autocomplete: (previous, { toReference }) => {
              return [...previous, toReference(createOrganization)]
            },
          },
        })
      },
      onCompleted: ({ createOrganization }) => {
        const { id, name, location } = createOrganization
        done(`${name} - ${location.city}, ${location.country}`, id)
        setSuccessMsg({
          title: `Successfully added ${name}`,
        })
        onClose()
      },
      onError: (error) => {
        setAlertMsg({
          title: 'Error',
          content: error.message,
        })
        onClose()
      },
    }
  )

  const [updateOrganization, { loading: updating }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      update: (cache, { data: { updateOrganization } }) => {
        cache.modify({
          fields: {
            autocomplete: (previous, { toReference }) => {
              return [...previous, toReference(updateOrganization)]
            },
          },
        })
      },
      onCompleted: ({ updateOrganization }) => {
        const { id, name, location } = updateOrganization
        done(`${name} - ${location.city}, ${location.country}`, id)
        setSuccessMsg({
          title: `Successfully updated ${name}`,
        })
        onClose()
      },
      onError: (error) => {
        setAlertMsg({
          title: 'Error',
          content: error.message,
        })
        onClose()
      },
    }
  )

  const clearFieldsAndClose = () => {
    setName(defaultQuery)
    setOrganizationId(null)
    setSize('')
    setType('')
    setCountry('')
    setCity('')
    setUrl('')
    setErrorMsg('')
    setFormError({})
    onClose()
  }

  const hasEmptyFields = () => {
    formError.name = !name
    formError.size = !size
    formError.type = !type
    formError.country = !country
    formError.city = !city
    setFormError(formError)
    return Object.values(formError).includes(true)
  }

  const onCreate = () => {
    setErrorMsg('')
    if (hasEmptyFields()) {
      setErrorMsg('Please fill in all the required fields')
      return
    }
    const ORGANIZATION_INPUT = {
      name,
      size,
      type,
      location: { city, country },
      url: url || undefined,
    }
    if (organizationId) {
      updateOrganization({
        variables: {
          organizationId: organizationId,
          organization: ORGANIZATION_INPUT,
        },
      })
    } else {
      createOrganization({
        variables: {
          organization: ORGANIZATION_INPUT,
        },
      })
    }
  }

  return (
    <Modal closeIcon open={open} onClose={clearFieldsAndClose}>
      <Modal.Header>Add a new organization</Modal.Header>
      <Modal.Content>
        <i style={{ color: 'red' }}>{errorMsg}</i>
        <Form>
          <Form.Group widths="equal">
            <Autocomplete
              error={formError?.name}
              label="Name"
              placeholder="Enter organization name"
              context="OpenAlex"
              readOnly={organizationId}
              value={name}
              onChange={(value) => {
                setName(value)
                setOrganizationId(null)
              }}
              onSelect={({ organizationId, organization }) => {
                setOrganizationId(organizationId)
                if (organization) {
                  setName(organization.name)
                  setCountry(organization.location.country)
                  setCity(organization.location.city)
                  setUrl(organization.url || '')
                }
              }}
            />
            <Form.Dropdown
              error={formError.size}
              selection
              label="Organization size (# FTEs)"
              placeholder="Select organization's size"
              options={ORG_SIZE.map((size) => ({
                key: size,
                value: size,
                text: size,
              }))}
              value={size}
              onChange={(e, { value }) => setSize(value)}
            />
          </Form.Group>
          <Form.Dropdown
            error={formError.type}
            search
            selection
            label="Type"
            placeholder="Enter organization type ( e.g, Biopharma, University, Academic Medical Center )"
            options={ORG_TYPES.map((type) => ({
              key: type,
              value: type,
              text: type,
            }))}
            value={type}
            onChange={(e, { value }) => setType(value)}
          />
          <Form.Group widths="equal">
            <Form.Dropdown
              error={formError.country}
              search
              selection
              label="Country"
              placeholder="Select organization's country"
              options={Object.keys(COUNTRIES_TO_CODES).map((country) => ({
                key: country,
                value: country,
                text: country,
              }))}
              value={country}
              onChange={(e, { value }) => setCountry(value)}
            />
            <Form.Input
              error={formError.city}
              label="City"
              placeholder="Enter organization's city"
              value={city}
              onChange={(e, { value }) => setCity(value)}
            />
          </Form.Group>
          <Form.Input
            label="Organization URL (Optional)"
            placeholder="https://www.example.org"
            value={url}
            onChange={(e, { value }) => setUrl(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={clearFieldsAndClose} />
        <Button
          primary
          loading={creating || updating}
          disabled={creating || updating}
          content="Add"
          onClick={onCreate}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default NewOrganizationModal
