import React from 'react'
import Routes from './Routes'
import { AuthProvider } from './contexts/AuthContext'
import 'semantic-ui-css/semantic.min.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles/styles.scss'

const App = () => (
  <AuthProvider>
    <Routes />
  </AuthProvider>
)

export default App
