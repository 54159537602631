import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Dropdown, Header, Form, Icon } from 'semantic-ui-react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import {
  FUNCTIONS,
  TECHNOLOGY_TAGS,
  MEDICAL_SPECIALTIES,
} from '../../constants/categories'
import { getEditorStateFromHTML } from '../../utils/helpers'
import DataContext from '../../contexts/DataContext'

const Expertis = (props, ref) => {
  const { advisor } = useContext(DataContext)

  const EXPERT_TAG_DATA = {
    functions: {
      availableTags: FUNCTIONS,
      expertTags: advisor.expert?.functions || [],
      suggestedTags:
        advisor.expert?.tagSuggestions
          .filter((ts) => ts.category === 'functions')
          .map((ts) => ts.tag) || [],
    },
    technology_tags: {
      availableTags: TECHNOLOGY_TAGS,
      expertTags: advisor.expert?.technology_tags || [],
      suggestedTags:
        advisor.expert?.tagSuggestions
          .filter((ts) => ts.category === 'technology_tags')
          .map((ts) => ts.tag) || [],
    },
    medical_specialties: {
      availableTags: MEDICAL_SPECIALTIES,
      expertTags: advisor.expert?.medical_specialties || [],
      suggestedTags:
        advisor.expert?.tagSuggestions
          .filter((ts) => ts.category === 'medical_specialties')
          .map((ts) => ts.tag) || [],
    },
  }

  const getOptions = (category) => {
    const expertTagData = EXPERT_TAG_DATA[category]
    const defaultOptions = expertTagData.availableTags
      .map((obj) => {
        const header = obj.header && {
          key: obj.header,
          text: obj.header,
          value: obj.header,
          content: (
            <Header
              style={{
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: '12px',
              }}
            >
              <Icon name={obj.icon} color="black" />
              {obj.header}
            </Header>
          ),
          disabled: true,
          style: { opacity: 1 },
        }
        const items = obj.items.map((item) => ({
          key: item,
          text: item,
          value: item,
        }))
        return header ? [header, ...items] : [...items]
      })
      .flat(1)

    const userCreatedOptions = expertTagData.expertTags
      .filter((tag) => !defaultOptions.map((item) => item.value).includes(tag))
      .map((tag) => ({ key: tag, text: tag, value: tag }))

    const tagSuggestionsOptions = expertTagData.suggestedTags.map((tag) => ({
      key: tag,
      text: tag,
      value: tag,
      type: 'tagSuggestion',
    }))

    return defaultOptions.concat(userCreatedOptions, tagSuggestionsOptions)
  }

  const setExpertTags = (category) =>
    EXPERT_TAG_DATA[category].expertTags.concat(
      EXPERT_TAG_DATA[category].suggestedTags
    )

  const [description, setDescription] = useState(advisor.expert?.description)
  const [functions, setFunctions] = useState(setExpertTags('functions'))
  const [technologyTags, setTechnologyTags] = useState(
    setExpertTags('technology_tags')
  )
  const [medicalSpecialties, setMedicalSpecialties] = useState(
    setExpertTags('medical_specialties')
  )
  const [editorState, setEditorState] = useState(
    getEditorStateFromHTML(advisor.expert?.description)
  )
  const [functionOptions, setFunctionOptions] = useState(
    getOptions('functions')
  )
  const [technologyOptions, setTechnologyOptions] = useState(
    getOptions('technology_tags')
  )
  const [medicalSpecialtiesOptions, setMedicalSpecialtiesOptions] = useState(
    getOptions('medical_specialties')
  )

  useImperativeHandle(ref, () => {
    return {
      state: {
        description,
        functions,
        technologyTags,
        medicalSpecialties,
      },
    }
  })

  const handleItem = (type, item) => {
    const handleTypeMethod = (list, setList) => {
      if (list.indexOf(item) === -1) setList(list.concat(item))
      else setList(list.filter((elem) => elem !== item))
    }
    switch (type) {
      case 'function':
        handleTypeMethod(functions, setFunctions)
        break
      case 'technology':
        handleTypeMethod(technologyTags, setTechnologyTags)
        break
      case 'med':
        handleTypeMethod(medicalSpecialties, setMedicalSpecialties)
        break
      default:
        break
    }
  }

  const renderLabel = (item) => {
    const label = { content: item.text }
    if (
      item.type === 'tagSuggestion' &&
      advisor.expert.tagSuggestions.length > 0
    ) {
      label.className = 'blue-box'
    }
    return label
  }

  const dropdownAttributes = {
    placeholder:
      'Add all that apply from the dropdown and/or Enter a new value',
    fluid: true,
    multiple: true,
    search: true,
    selection: true,
    allowAdditions: true,
  }

  return (
    <div id="expertise-section">
      <Header as="h4">Expertise</Header>
      <div className="content">
        <Form>
          <Form.Field>
            <label>Functions</label>
            <Dropdown
              {...dropdownAttributes}
              value={functions}
              options={functionOptions}
              onAddItem={(e, { value }) => {
                setFunctionOptions([{ text: value, value }, ...functionOptions])
                handleItem('function', value)
              }}
              onChange={(e, { value }) => setFunctions(value)}
              renderLabel={renderLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>Technologies</label>
            <Dropdown
              {...dropdownAttributes}
              value={technologyTags}
              options={technologyOptions}
              onAddItem={(e, { value }) => {
                setTechnologyOptions([
                  { text: value, value },
                  ...technologyOptions,
                ])
                handleItem('technology', value)
              }}
              onChange={(e, { value }) => setTechnologyTags(value)}
              renderLabel={renderLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>Medical Specialties and/or Disease/Research Areas</label>
            <Dropdown
              {...dropdownAttributes}
              value={medicalSpecialties}
              options={medicalSpecialtiesOptions}
              onAddItem={(e, { value }) => {
                setMedicalSpecialtiesOptions([
                  { text: value, value },
                  ...medicalSpecialtiesOptions,
                ])
                handleItem('med', value)
              }}
              onChange={(e, { value }) => setMedicalSpecialties(value)}
              renderLabel={renderLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>
              Please use the box below to describe your experience in more
              detail
            </label>
            <Editor
              toolbar={{ options: ['inline', 'list'] }}
              editorState={editorState}
              onEditorStateChange={(editorState) => {
                setEditorState(editorState)
                setDescription(
                  JSON.stringify(convertToRaw(editorState.getCurrentContent()))
                )
              }}
            />
          </Form.Field>
        </Form>
      </div>
    </div>
  )
}

export default forwardRef(Expertis)
