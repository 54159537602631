import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Modal, Button, Form, Input } from 'semantic-ui-react'
import { UPDATE_PROJECT_MUTATION } from '../../graphql/project'
import { SEND_EMAIL_MUTATION } from '../../graphql/sendgrid'
import {
  getReferEmailToAdmin,
  getReferEmailToRecipient,
} from '../Emails/ReferEmail'
import { getHTMLFromEditorState } from '../../utils/helpers'
import DataContext from '../../contexts/DataContext'
import MessageContext from '../../contexts/MessageContext'

const ReferralModal = ({ project, children }) => {
  const { advisor } = useContext(DataContext)
  const { setAlertMsg } = useContext(MessageContext)

  const { id, referrals, owners, title, description } = project
  const { email, firstName, lastName } = advisor

  const [open, setOpen] = useState(false)
  const [refererName, setRefererName] = useState('')
  const [refererEmail, setRefererEmail] = useState('')
  const [refererLinkedin, setRefererLinkedin] = useState('')

  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
  const [updateRefer] = useMutation(UPDATE_PROJECT_MUTATION)

  const onSendEmail = (
    recipient,
    sender,
    subject,
    text,
    html,
    shouldReload
  ) => {
    sendEmail({
      variables: {
        input: {
          to: recipient,
          from: sender,
          subject,
          text,
          html,
        },
      },
    })
  }

  const onRefer = () => {
    const isValidated = () => {
      return refererName !== '' && refererEmail !== ''
    }

    const isExisted = (referrals, email) => {
      for (let i = 0; i < referrals.length; i++) {
        let referral = referrals[i]
        if (referral.referer_email === email) return true
      }
      return false
    }

    if (!isValidated()) {
      return setAlertMsg({
        title: 'Error',
        content: 'Please fill out all fields',
      })
    }

    if (isExisted(referrals, refererEmail)) {
      return setAlertMsg({
        title: 'Oops !',
        content: 'You might have already referred to this email !',
      })
    }

    referrals.push({
      expert_name: `${firstName} ${lastName}`,
      expert_email: email,
      referer_name: refererName,
      referer_email: refererEmail,
      referer_linkedin: refererLinkedin,
    })

    updateRefer({
      variables: {
        id,
        input: { referrals },
      },
      update: (cache) => {
        const getEmailObj = (email, name) => ({
          email,
          name,
        })

        //send email to admin
        onSendEmail(
          getEmailObj(owners[0].email, owners[0].email),
          getEmailObj('help@dexterexperts.com', 'Dexter'),
          `Dexter | ${title} An expert has referred someone for this project`,
          'Dexter An Expert has referred someone',
          getReferEmailToAdmin(
            `${firstName} ${lastName}`,
            title,
            refererName,
            refererEmail,
            refererLinkedin
          )
        )

        //send email to recipient
        onSendEmail(
          getEmailObj(refererEmail),
          getEmailObj('help@dexterexperts.com', 'Dexter'),
          "Expert opinion needed! You've been referred by your friend",
          'Dexter email send to referral recipient',
          getReferEmailToRecipient(
            refererName,
            `${firstName} ${lastName}`,
            title,
            getHTMLFromEditorState(description)
          )
        )

        setAlertMsg({
          title: 'Thanks for sharing!',
          content: 'We will get in touch with them.',
        })
        setOpen(false)
      },
    })
  }

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
    >
      <Modal.Header> Refer a friend</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required id="referer_name_field">
              <label>Name</label>
              <Input
                placeholder="Enter name"
                value={refererName}
                onChange={(e, { value }) => setRefererName(value)}
              />
            </Form.Field>
            <Form.Field required id="referer_email_field">
              <label>Email</label>
              <Input
                placeholder="Enter email"
                value={refererEmail}
                onChange={(e, { value }) => setRefererEmail(value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Linkedin (Recommanded)</label>
            <Input
              placeholder="https://linkedin.com"
              value={refererLinkedin}
              onChange={(e, { value }) => setRefererLinkedin(value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onRefer}>Send invitation</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ReferralModal
