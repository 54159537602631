import React from 'react'
import { Header, Divider } from 'semantic-ui-react'

import QuestionItem from './QuestionItem'
import questions from '../../../constants/questions.json'
import GridContainer from './LogoGrid/GridContainer'
import './QuestionList.css'

const QuestionList = () => {
  return (
    <>
      <Divider horizontal>
        <Header className="sub_header" sub>
          <span className="highlight">FAQ</span>
        </Header>
      </Divider>
      <Header className="main_header" size="medium">
        Have Any Questions?
      </Header>

      {questions.map((question, index) => {
        return (
          <QuestionItem key={index} headerText={question.headerText}>
            {question.bodyText}
            {question.grid ? <GridContainer /> : null}
          </QuestionItem>
        )
      })}
    </>
  )
}

export default QuestionList
