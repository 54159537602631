import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment-timezone'
import CustomTooltip from './CustomTooltip'

const TranscationGraph = ({ advisor, jobs, present }) => {
  const yearMap = new Map()
  jobs.forEach((job) => {
    const time = moment(job.payment.createdAt).format(present)
    const list = yearMap.get(time) || []
    list.push(job.project || {})
    yearMap.set(time, list)
  })

  const getPaymentKey = (job) =>
    `${job.project.title} (${moment
      .tz(job.payment?.createdAt, advisor.timeZone)
      .format('l')})`

  const data = Array.from(yearMap.keys()).map((time) => {
    const obj = {}
    obj.name = time
    yearMap.get(time).forEach((project) => {
      const job = jobs.filter((p) => p.project.id === project.id)[0]
      const key = getPaymentKey(job)
      obj[key] = job.payment.amount
    })
    return obj
  })

  const projectKeys = jobs.map((job) => getPaymentKey(job))

  return (
    <div className="histogram">
      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis tickFormatter={(value) => `$${value}`} />
          <Tooltip content={CustomTooltip} cursor={{ fill: 'white' }} />
          {projectKeys.map((key) => {
            return (
              <Bar
                key={key}
                stroke="white"
                dataKey={key}
                stackId="a"
                fill="#4BC4C2"
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default TranscationGraph
