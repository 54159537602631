import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Card, Image, Icon, Popup } from 'semantic-ui-react'
import moment from 'moment-timezone'
import { getHTMLFromEditorState } from '../../utils/helpers'
import { LOAD_SINGLE_PROJECT_QUERY } from '../../graphql/project'
import Active from './CardFooter/Active'
import Pending from './CardFooter/Pending'
import Past from './CardFooter/Past'
import ProjectSettings from './ProjectSettings'

const ProjectCard = ({ project, category, job }) => {
  const CHARACTERS_LIMIT = 260
  const [showContent, setShowContent] = useState(false)

  const { data, loading } = useQuery(LOAD_SINGLE_PROJECT_QUERY, {
    variables: { id: project.id },
  })

  const getStatus = () => {
    if (
      job.overall_status === 'Client No Show' ||
      job.overall_status === 'Expert No Show'
    ) {
      return 'No Show'
    }
    return job.overall_status
  }

  const renderCardFooter = () => {
    switch (category) {
      case 'active':
        return <Active project={data.project} job={job} />
      case 'pending':
        return <Pending project={data.project} job={job} />
      case 'past':
        return <Past project={data.project} job={job} />
      default:
        return <div />
    }
  }

  const renderOfferRate = () => {
    if (data.project.offerRate) {
      return (
        <p>
          {`Honorarium: $${data.project.offerRate} ${
            data.project.type === 'Interview'
              ? ' per hour (pro-rated)'
              : ' for submission of a complete survey response'
          }`}
        </p>
      )
    }
    return ''
  }

  const renderStatus = () => {
    if (category === 'active') return <div>Accepted</div>
    if (category === 'past') {
      const status = getStatus()
      const date = job.completedAt
      return (
        <div>
          {status}
          {date && ` | ${moment(date).format('LL')}`}
        </div>
      )
    }
    return <div />
  }

  if (loading) return <div />
  const descriptionHTML = getHTMLFromEditorState(data.project.description)
  const partialDescriptionHTML = descriptionHTML.substring(0, CHARACTERS_LIMIT)
  const isLongDescription = descriptionHTML.length > CHARACTERS_LIMIT
  return (
    <Card>
      <Card.Content>
        {category === 'active' &&
          job.overall_status === 'Scheduled' &&
          data.project.type === 'Interview' &&
          job.meetingAt && <ProjectSettings meeting={job} />}
        <Popup
          trigger={
            <Image
              floated="right"
              size="mini"
              src={
                data.project.type === 'Interview'
                  ? `${process.env.REACT_APP_AWS_S3_URL}/interview.svg`
                  : `${process.env.REACT_APP_AWS_S3_URL}/survey.svg`
              }
            />
          }
          content={`${data.project.type}`}
          position="top left"
        />
        <Card.Header>{data.project.title}</Card.Header>
        <Card.Meta>{renderStatus()}</Card.Meta>
        <Card.Description className="content-description">
          {renderOfferRate()}
          <div
            dangerouslySetInnerHTML={{
              __html:
                isLongDescription && !showContent
                  ? `${partialDescriptionHTML}...`
                  : descriptionHTML,
            }}
          ></div>

          {isLongDescription && (
            <div
              className="show-content-button"
              onClick={() => {
                setShowContent(!showContent)
              }}
            >
              {showContent ? (
                <span>
                  Show less
                  <Icon name="angle up" />
                </span>
              ) : (
                <span>
                  Show more
                  <Icon name="angle down" />
                </span>
              )}
            </div>
          )}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>{renderCardFooter()}</Card.Content>
    </Card>
  )
}

export default ProjectCard
