import React from 'react'
import { Link } from 'react-router-dom'

const BoxLink = ({ to, title, content }) => (
  <Link to={to}>
    <h5>{title}</h5>
    <hr />
    <p>
      <strong>{content}</strong>
    </p>
  </Link>
)

export default BoxLink
