import React from 'react'

import { Grid } from 'semantic-ui-react'
import Link from '../Link'
const GridItem = (props) => {
  return (
    <Grid.Column>
      <Link link={props.link} title={props.title}>
        {' '}
        {props.children}{' '}
      </Link>
    </Grid.Column>
  )
}

export default GridItem
