import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Modal, List, Loader } from 'semantic-ui-react'
import moment from 'moment-timezone'
import { LOAD_MEETING_INFO_QUERY } from '../../graphql/schedule'
import DataContext from '../../contexts/DataContext'
import MeetingInvite from '../MeetingInvite'

const MeetingModal = ({ job, children }) => {
  const { advisor } = useContext(DataContext)
  const [open, setOpen] = useState(false)
  const { data, error, loading } = useQuery(LOAD_MEETING_INFO_QUERY, {
    skip: !open,
    variables: { id: job.id },
  })

  if (loading) return <Loader active inline />

  const meetingTime = moment.tz(job.meetingAt, advisor.timeZone).format('lll z')
  const expertName = job.user.fullName || job.user.email

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
    >
      <Modal.Header>
        {`${expertName} | ${job.project.title} at ${meetingTime}`}
      </Modal.Header>
      <Modal.Content>
        <List>
          <List.Item>
            <strong>Expert: </strong>
            {expertName}
          </List.Item>
          <List.Item>
            <strong>Project: </strong>
            {job.project.title}
          </List.Item>
          <List.Item>
            <strong>Meeting time: </strong>
            {meetingTime}
          </List.Item>
          {error ? (
            <pre>Failed to fetch meeting invite</pre>
          ) : (
            <MeetingInvite
              meetingInvite={data?.job.meetingInvite}
              meetingLink={data?.job.meetingLink}
            />
          )}
        </List>
      </Modal.Content>
    </Modal>
  )
}

export default MeetingModal
