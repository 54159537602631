import { gql } from '@apollo/client'
import { PROJECT_MODEL } from './modelFields'

export const LOAD_SINGLE_PROJECT_QUERY = gql`
  query Project($id: String!) {
    project(id: $id) {
      ${PROJECT_MODEL}
    }
  }
`

export const SINGLE_PROJECT_PUBLIC_QUERY = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      status
      title
      description
      type
      compensationType {
        name
        params
      }
      qualificationQuestions {
        id
        question
        questionType
        answerChoices
        otherIncluded
      }
    }
  }
`

export const PROJECT_OFFERRATE = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      offerRate
    }
  }
`

export const PROJECT_CALENDAR = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      start_date
      end_date
      calendar {
        date
        entries {
          startTime
          status
        }
      }
    }
  }
`

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      ${PROJECT_MODEL}
    }
  }
`
