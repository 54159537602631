import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Header, Message, Accordion } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import AuthContext from '../../contexts/AuthContext'
import NotFoundPage from '../NotFoundPage'

const QUESTIONS = [
  {
    title: {
      content: 'Why do I need to create a profile in Dexter?',
      icon: 'question',
    },
    key: 1,
    content:
      'We ask you to create a profile in Dexter to make sure you get paid for your time and to learn a little bit more about you before our engagement. This also allows us to keep you in the loop for any important notifications before and after the engagement.',
  },
  {
    key: 2,
    title: { content: 'How do I sign up on Dexter?', icon: 'question' },
    content: {
      content: (
        <div>
          Click on the &quot;Get Started&quot; button, then navigate to the
          &quot;Sign up&quot; tab and enter your email address and password.
          Check your inbox to verify your email address. Come back to this page,
          click on the button again, and enter your credentials in the
          &quot;Login&quot; tab.
        </div>
      ),
    },
  },
]

const PublicProjectAuthenticate = () => {
  const { loginWithRedirect } = useContext(AuthContext)
  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(-1)
  const params = new URLSearchParams(location.search)
  const state = params.get('state')
  const publicProject = JSON.parse(sessionStorage.getItem('publicProject'))
  const qualification = JSON.parse(sessionStorage.getItem('qualification'))

  const handleTitleClick = (e, itemProps) => {
    const { index } = itemProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      {state === 'login' && (
        <Message
          info
          header="Did you sign up?"
          content="Check your email to verify your email address. Then, click on the Login button below to log in."
        />
      )}

      {!publicProject || !qualification ? (
        <NotFoundPage message="If you received an invitation to a Dexter project, please go back to the invitation link and start over." />
      ) : (
        <React.Fragment>
          <div id="public-project-register">
            <Header sub>Register</Header>
            <Header size="medium">
              Awesome! It looks like this project is a good match for you
            </Header>
            <div>
              We will take you through a couple of steps to help you{' '}
              {publicProject.type === 'Interview'
                ? 'book a time for your Zoom meeting.'
                : 'get started with the survey.'}{' '}
              The first step is to set up your profile in Dexter. This will take
              just a few minutes to complete. Click on the button below to get
              started.
            </div>
            <div style={{ paddingTop: '1rem' }}>
              <Accordion
                panels={QUESTIONS}
                onTitleClick={handleTitleClick}
                activeIndex={activeIndex}
              />
            </div>
            <div id="public-project-btn">
              <Button
                primary
                onClick={() => {
                  MixpanelTracker.trackPublicProjectLandingSignUp('Accepted')
                  loginWithRedirect({
                    appState: {
                      returnTo: '/landing/register',
                    },
                  })
                }}
              >
                {state === 'login' ? 'Log in' : 'Get started'}
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default PublicProjectAuthenticate
