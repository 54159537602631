import React from 'react'
import { Form } from 'semantic-ui-react'

const ShortAnswer = ({ question, rawResponses, updateRawResponses }) => {
  const handleChange = (question, value) => {
    let temp
    const index = rawResponses.findIndex((q) => q.question === question)
    if (index === -1) {
      temp = rawResponses.concat({
        response: [value],
        question,
      })
    } else {
      temp = rawResponses.map((q) =>
        q.question === question ? { ...q, response: [value] } : q
      )
    }
    updateRawResponses(temp)
  }
  return (
    <div className="qualification-question">
      <Form.TextArea
        key={`${question.questionuestion}${question.questionType}`}
        placeholder="Enter your response"
        value={
          rawResponses.find((i) => i.question === question.question)
            ?.response[0]
        }
        onChange={(e) => handleChange(question.question, e.target.value)}
      />
    </div>
  )
}

export default ShortAnswer
