import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'

import Calendar from './Calendar'
import TimeSlotList from './TimeSlotList'

const SchedulerConent = ({ project, selectedTime, setSelectedTime }) => {
  const [currentDate, setCurrentDate] = useState(
    selectedTime ? new Date(selectedTime) : new Date()
  )

  return (
    <Grid stackable>
      <Grid.Column tablet={16} computer={11}>
        <Calendar
          project={project}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
      </Grid.Column>
      <Grid.Column tablet={16} computer={5}>
        <TimeSlotList
          project={project}
          currentDate={currentDate}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      </Grid.Column>
    </Grid>
  )
}

export default SchedulerConent
