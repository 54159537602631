import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { Grid, Menu } from 'semantic-ui-react'
import Personal from '../components/Profile/Personal'
import Notifications from '../components/Profile/Notifications'
import Account from '../components/Profile/Account'

const Profile = () => {
  const history = useHistory()
  const match = useRouteMatch('/profile/:page')
  const [activeItem, setActiveItem] = useState(
    match ? match.params.page : 'profile'
  )

  // Update menu tab when the url params changed
  useEffect(() => setActiveItem(match?.params.page || 'profile'), [match])

  const handleItemClick = (e, { name }) => {
    setActiveItem(name)
    history.push(name === 'profile' ? '/profile' : `/profile/${name}`)
  }

  return (
    <Grid container stackable>
      <div id="profile">
        <Menu tabular>
          <Menu.Item
            name="profile"
            active={activeItem === 'profile'}
            onClick={handleItemClick}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            name="notifications"
            active={activeItem === 'notifications'}
            onClick={handleItemClick}
          >
            Notifications
          </Menu.Item>
          <Menu.Item
            name="account"
            active={activeItem === 'account'}
            onClick={handleItemClick}
          >
            Account
          </Menu.Item>
        </Menu>
        <div className="profile-title">
          <div className="user-info">
            <Switch>
              <Route path="/profile/account" component={Account} />
              <Route path="/profile/notifications" component={Notifications} />
              <Route path="/profile" component={Personal} />
            </Switch>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default Profile
