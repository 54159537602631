import React from 'react'
import { Grid, Segment } from 'semantic-ui-react'

const FooterLink = ({ href, text }) => (
  <>
    <a
      href={href}
      rel="noopener noreferrer"
      target="_blank"
      className="text-primary"
    >
      {text}
    </a>
    {' | '}
  </>
)

const DashboardFooter = () => (
  <Grid stackable>
    <Segment inverted className="dashboard-footer">
      <p>
        <span>All right reserved</span>
        {' | '}
        <FooterLink href="/" text="Dexter Home" />
        <FooterLink
          href="https://www.dexterexperts.com/terms-of-service"
          text="Terms of Use"
        />
        <FooterLink
          href="https://www.dexterexperts.com/privacy-policy"
          text="Privacy Policy"
        />
      </p>
    </Segment>
  </Grid>
)

export default DashboardFooter
