import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Dropdown, Statistic, Message } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import {
  CREATE_STRIPE_LOGIN_LINK_MUTATION,
  DISCONNECT_STRIPE_MUTATION,
} from '../../graphql/stripe'
import TransactionGraph from './TransactionGraph'
import DataContext from '../../contexts/DataContext'

const ConnectPanel = () => {
  const { advisor, balance } = useContext(DataContext)

  const [graphOption, setGraphOption] = useState('month')
  const [dashboard, setDashboard] = useState(
    'https://dashboard.stripe.com/dashboard'
  )

  const [disconnectStripe] = useMutation(DISCONNECT_STRIPE_MUTATION)
  const [getStripeLoginLink] = useMutation(CREATE_STRIPE_LOGIN_LINK_MUTATION)

  useEffect(() => {
    getStripeLoginLink({
      variables: {
        stripe_account_id: advisor.expert.stripe_account,
      },
      update: (cache, { data: { createStripeLoginLink } }) => {
        if (!createStripeLoginLink.success) {
          console.log(createStripeLoginLink.error)
        } else {
          MixpanelTracker.trackViewStripe()
          setDashboard(createStripeLoginLink.uri)
        }
      },
    })
  }, [advisor.expert.stripe_account, getStripeLoginLink])

  const transcationOptions = [
    {
      key: 'month',
      text: 'Monthly',
      value: 'month',
    },
    {
      key: 'year',
      text: 'Yearly',
      value: 'year',
    },
  ]

  const disconnectToStripe = () => {
    disconnectStripe({
      update: (cache, { data: { disconnectExpertFromStripe } }) => {
        if (!disconnectExpertFromStripe.success) {
          alert('Error: Failed to disconnect from Stripe')
          console.log(disconnectExpertFromStripe.error)
        } else {
          alert('Disconnected')
          MixpanelTracker.trackDisconnectFromStripe()
          window.location.reload()
        }
      },
    })
  }

  const paidJobs = advisor.jobs.filter((job) => job.overall_status === 'Paid')

  return (
    <div>
      <div style={{ marginLeft: '5%' }}>
        <h1>Here is your payment history</h1>
      </div>
      <div className="bank-section">
        <div className="connect-section transcation-section row">
          <div className="transcation-graph col-lg-12 col-md-12 col-sm-12 col-12">
            {paidJobs.length === 0 ? (
              <div>
                <Message color="yellow" className="no-data-message">
                  Hmm... Looks like you don&apos;t have any payment data for
                  this time period
                </Message>
                <img
                  src={`${process.env.REACT_APP_AWS_S3_URL}/empty_graph.svg`}
                  alt="empty graph"
                  className="empty-graph"
                />
              </div>
            ) : (
              <div>
                <Dropdown
                  selection
                  options={transcationOptions}
                  value={graphOption}
                  id="transcation-graph-dropdown"
                  onChange={(e, { value }) => setGraphOption(value)}
                ></Dropdown>
                <TransactionGraph
                  advisor={advisor}
                  jobs={paidJobs}
                  present={graphOption === 'month' ? 'MM/YYYY' : 'YYYY'}
                />
              </div>
            )}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12 transcation-info">
            <div className="data-part">
              <Statistic.Group>
                <Statistic>
                  <Statistic.Value>${balance}</Statistic.Value>
                  <Statistic.Label>Total earned</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>{paidJobs.length}</Statistic.Value>
                  <Statistic.Label>Total engagements</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            </div>
            <div className="button-part">
              <a
                href={dashboard}
                className="ui button primary"
                rel="noopener noreferrer"
                target="_blank"
              >
                View Details
              </a>
              <br />
              <span id="disconnect-stripe-btn" onClick={disconnectToStripe}>
                Disconnect Stripe from Dexter
              </span>
            </div>
          </div>
          <div className="ui icon message">
            <i aria-hidden="true" className="lightbulb icon"></i>
            <div className="content">
              <span style={{ fontWeight: '900' }}>Tip</span>: You can receive
              more project opportunities by adding more tags to your{' '}
              <Link to="/profile" className="profile-link">
                profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectPanel
