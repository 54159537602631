import React from 'react'
import Alert from '../Alert.js'

const PreInterviewContent = ({ preInterviewLink }) => {
  return (
    <React.Fragment>
      To help guide the discussion, the interviewer has shared some material
      that you can review before the call.
      <p>
        You can access the material using the following link:{' '}
        <a href={preInterviewLink} target="_blank" rel="noreferrer">
          Pre-interview Material
        </a>
      </p>
      <p>
        Taking a moment to scan through the provided material will enable a more
        productive and focused conversation during the call.
      </p>
    </React.Fragment>
  )
}
const PreInterviewModal = ({ project, onClose }) => {
  return (
    <Alert
      title={`Pre-interview Material for ${project.title}`}
      content={
        <PreInterviewContent preInterviewLink={project.preInterviewLink} />
      }
      onClose={onClose}
    />
  )
}

export default PreInterviewModal
