import React, { useContext, useState } from 'react'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { Header, Icon } from 'semantic-ui-react'
import { UPDATE_EXPERT } from '../graphql/expert'
import DataContext from '../contexts/DataContext'

const Nps = () => {
  const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const { advisor } = useContext(DataContext)
  const [showNps, setShowNps] = useState(
    (advisor.expert.nps.length === 0 ||
      moment(advisor.expert.nps[advisor.expert.nps.length - 1].date).add(
        3,
        'M'
      ) <= moment()) &&
      advisor.jobs.find((job) => job.completedAt) !== undefined
  )
  const handleClose = () => setShowNps(false)
  const [updateExpert] = useMutation(UPDATE_EXPERT)

  const recordNPS = (score) => {
    updateExpert({
      variables: {
        input: {
          nps: advisor.expert.nps.concat({ score, date: new Date() }),
        },
      },
      onCompleted: () => {
        if (score >= 7) {
          window.open('/referral', '_blank')
        }
        setShowNps(false)
      },
    })
  }

  return showNps ? (
    <div id="nps-section">
      <div className="nps-dashboard">
        <Header as="h4">
          How likely are you to recommend Dexter to a colleague or friend?
          <Header sub floated="right" onClick={handleClose}>
            <Icon name="close" />
          </Header>
        </Header>

        {NUMBERS.map((num, index) => {
          return (
            <a
              key={index}
              className="nps-number"
              href={`https://survey.survicate.com/28439bc346fbd444/?p=sendgrid&email=[%email%]&aid=${num}`}
              onClick={() => recordNPS(num)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {num}
            </a>
          )
        })}
        <div className="scale">
          <span style={{ float: 'left' }}>Not likely</span>
          <span style={{ float: 'right' }}>Very likely</span>
        </div>
      </div>
      <a
        href="https://survicate.com"
        target="_blank"
        rel="noopener noreferrer"
        title="Survicate"
        className="survicate"
      >
        Powered by Survicate
      </a>
    </div>
  ) : (
    <div />
  )
}

export default Nps
