export const getWelcomeEmail = (name) => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" /><!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" /><!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
      body {width: 600px;margin: 0 auto;}
      table {border-collapse: collapse;}
      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
      img {-ms-interpolation-mode: bicubic;}
    </style>
    <![endif]-->

    <style type="text/css">
      body, p, div {
        font-family: arial,helvetica,sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #1188E6;
        text-decoration: none;
      }
      p { margin: 0; padding: 0; }
      table.wrapper {
        width:100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      @media screen and (max-width:480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
            text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 480px !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    </style>
    <!--user entered Head Start-->
    
     <!--End Head user entered-->
  </head>
  <body>
    <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size: 14px; font-family: arial,helvetica,sans-serif; color: #000000; background-color: #ffffff;">
      <div class="webkit">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#ffffff">
          <tr>
            <td valign="top" bgcolor="#ffffff" width="100%">
              <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td width="100%">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                      <tr>
                        <td>
                          <!--[if mso]>
                          <center>
                          <table><tr><td width="600">
                          <![endif]-->
                          <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width: 100%; max-width:600px;" align="center">
                            <tr>
                              <td role="modules-container" style="padding: 0px 0px 0px 0px; color: #000000; text-align: left;" bgcolor="#ffffff" width="100%" align="left">
                                
    <table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%"
           style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
      <tr>
        <td role="module-content">
          <p>Share your expertise and get paid</p>
        </td>
      </tr>
    </table>
  
    <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
      <tr>
        <td style="font-size:6px;line-height:10px;padding:0px 0px 0px 0px;" valign="top" align="center">
          <a href="${
            process.env.REACT_APP_BASE_URL
          }"><img class="max-width" border="0" style="display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:100% !important;width:35%;height:auto !important;" src="https://dexter-resource.s3.us-west-1.amazonaws.com/Dexter_Black.png" alt="" data-proportionally-constrained="true" width="600"></a>
        </td>
      </tr>
    </table>
  <table class="module" role="module" data-type="code" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
      <tr>
        <td height="100%" valign="top">
          <div style="color: rgb(34, 34, 34); font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: small; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">
<div style="line-height: 19.5px; color: rgb(0, 0, 0); font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; padding: 10px 20px;">
<div style="line-height: 20px; font-size: 13px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif;">
<p style="line-height: 21px; margin-bottom: 0px; font-size: 14px;"><span style="font-size:26px;"><span style="font-family:arial,helvetica,sans-serif;"><strong><span style="line-height: 54px;">Welcome to Dexter!</span></strong></span></span></p>
</div>
</div>
</div>

<div style="color: rgb(34, 34, 34); font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: small; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">
<div style="line-height: 19.5px; color: rgb(85, 85, 85); font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; padding: 10px 20px;">
<div style="line-height: 18px; font-size: 12px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif;">
<p style="margin-bottom: 0px; font-size: 18px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span style="line-height: 27px;">Hi </span><span style="font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; white-space: pre;">${
  name ? name : 'there'
}</span><span style="line-height: 27px;">,&nbsp;</span></span></span></p>

<p style="line-height: 21px; margin-bottom: 0px; font-size: 14px;">&nbsp;</p>

<p style="line-height: 18px; margin-bottom: 0px; font-size: 12px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span style="line-height: 27px;">Thanks for joining our expert network, Dexter!</span></span></span></p>

<p style="line-height: 18px; margin-bottom: 0px; font-size: 12px;">&nbsp;</p>

<p style="line-height: 18px; margin-bottom: 0px; font-size: 12px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span style="line-height: 27px;">Before you dive in to update your profile and get paid, check out our quick 2-minute introduction <a href="https://www.dropbox.com/s/lfkmsx9s4374c5g/Dexterv2%20expert%20onboarding.mp4?dl=0">video</a> to Dexter.<br style="line-height: inherit;">
<br style="line-height: inherit;">
You'll learn all the basics including:</span></span></span></p>
</div>
</div>
</div>

<div style="color: rgb(34, 34, 34); font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; font-size: 16px; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; text-align: center;">
<table border="0" cellpadding="0" cellspacing="0" style="line-height: 27px; vertical-align: top; font-size: 18px; font-family: Helvetica, Arial, sans-serif; text-align: left; margin-bottom: 20px; color: rgb(105, 105, 105); max-width: 600px !important;" width="100%">
	<tbody>
		<tr style="vertical-align: top; border-collapse: collapse;">
			<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; padding-left: 20px; padding-right: 20px; padding-top: 10px;">
			<table border="0" style="line-height: 27px; vertical-align: top; font-size: 18px; font-family: Helvetica, Arial, sans-serif; max-width: 600px !important;" width="100%">
				<tbody>
					<tr style="vertical-align: top; border-collapse: collapse;">
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; width: 24px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span class="sg-image" data-imagelibrary="%7B%22alignment%22%3A%22%22%2C%22link%22%3A%22http%3A//dashboard.dexterexperts.com/profile%22%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/30201f0d458e3c69d9955c79ac0831c11a55deb74fec94bb5acfcdf6fffac222de796646893728f034efdeb56b430676e8a9c8f4223dc9923947ce6f3a7cf2dc.png%22%2C%22height%22%3A%2236%22%2C%22width%22%3A%2236%22%2C%22widthPercent%22%3A100%2C%22isResponsive%22%3Afalse%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a href="${
              process.env.REACT_APP_BASE_URL
            }/profile"><img border="0" class="max-width" height="36" src="https://marketing-image-production.s3.amazonaws.com/uploads/30201f0d458e3c69d9955c79ac0831c11a55deb74fec94bb5acfcdf6fffac222de796646893728f034efdeb56b430676e8a9c8f4223dc9923947ce6f3a7cf2dc.png" style="color: rgb(0, 0, 0); text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px;" width="36"></a></span></span></span></td>
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse;">
						<div><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;">&nbsp;How to&nbsp;<strong><span style="color:#000000;">update your profile</span></strong>.</span></span></div>
						</td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
		<tr style="vertical-align: top; border-collapse: collapse;">
			<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; padding-left: 20px; padding-right: 20px; padding-top: 10px;">
			<table border="0" style="line-height: 27px; vertical-align: top; font-size: 18px; font-family: Helvetica, Arial, sans-serif; max-width: 600px !important;" width="100%">
				<tbody>
					<tr style="vertical-align: top; border-collapse: collapse;">
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; width: 24px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span class="sg-image" data-imagelibrary="%7B%22alignment%22%3A%22%22%2C%22link%22%3A%22https%3A//dashboard.dexterexperts.com/profile/bank%22%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/031249114f4c9cba0f24502d2e495789413bd62b2cf53b37dc53a655ba8848c6ea0a54f8d59db3fdee2b04d6622514a569c336871b4a292b7af238c025f49fef.png%22%2C%22height%22%3A%2236%22%2C%22width%22%3A%2236%22%2C%22widthPercent%22%3A100%2C%22isResponsive%22%3Afalse%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a href="${
              process.env.REACT_APP_BASE_URL
            }/profile/bank"><img border="0" class="max-width" height="36" src="https://marketing-image-production.s3.amazonaws.com/uploads/031249114f4c9cba0f24502d2e495789413bd62b2cf53b37dc53a655ba8848c6ea0a54f8d59db3fdee2b04d6622514a569c336871b4a292b7af238c025f49fef.png" style="color: rgb(0, 0, 0); text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px;" width="36"></a></span></span></span></td>
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;">&nbsp;How to&nbsp;<strong style="color: rgb(0, 0, 0);">link your bank account</strong>.</span></span></td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
		<tr style="vertical-align: top; border-collapse: collapse;">
			<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; padding-left: 20px; padding-right: 20px; padding-top: 10px;">
			<table border="0" style="line-height: 27px; vertical-align: top; font-size: 18px; font-family: Helvetica, Arial, sans-serif; max-width: 600px !important;" width="100%">
				<tbody>
					<tr style="vertical-align: top; border-collapse: collapse;">
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; width: 24px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span class="sg-image" data-imagelibrary="%7B%22alignment%22%3A%22%22%2C%22link%22%3A%22http%3A//dashboard.dexterexperts.com/project%22%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/bab188e32497e32c302f25ed230783eaeef1a05529a2c827b4aeaaf2bae2359e7e1309d19193c0f16776ab945979c0c314fcb39c2041eeaff06e64185aa6fce1.png%22%2C%22height%22%3A%2236%22%2C%22width%22%3A%2236%22%2C%22widthPercent%22%3A100%2C%22isResponsive%22%3Afalse%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a href="${
              process.env.REACT_APP_BASE_URL
            }/projects"><img border="0" class="max-width" height="36" src="https://marketing-image-production.s3.amazonaws.com/uploads/bab188e32497e32c302f25ed230783eaeef1a05529a2c827b4aeaaf2bae2359e7e1309d19193c0f16776ab945979c0c314fcb39c2041eeaff06e64185aa6fce1.png" style="color: rgb(0, 0, 0); text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px;" width="36"></a></span></span></span></td>
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span style="color: rgb(105, 105, 105); font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">&nbsp;How to&nbsp;</span><span style="font-weight: 600; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; color: rgb(0, 0, 0);">view and accept project opportunities</span><span style="font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; color: rgb(105, 105, 105);">.&nbsp;</span></span></span></td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
		<tr style="vertical-align: top; border-collapse: collapse;">
			<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; padding-left: 20px; padding-right: 20px; padding-top: 10px;">
			<table border="0" style="line-height: 27px; vertical-align: top; font-size: 18px; font-family: Helvetica, Arial, sans-serif; max-width: 600px !important;" width="100%">
				<tbody>
					<tr style="vertical-align: top; border-collapse: collapse;">
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse; width: 24px;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span class="sg-image" data-imagelibrary="%7B%22alignment%22%3A%22%22%2C%22link%22%3A%22https%3A//dashboard.dexterexperts.com/profile/notification%22%2C%22src%22%3A%22https%3A//marketing-image-production.s3.amazonaws.com/uploads/a3fd383f900edd0c1c3458a1eaffa51d188a10305a8227e495be85bcf44c03725bb1adc0f20de940fa37ebbad358030ac76721582dfd7de82f433a7c97287f82.png%22%2C%22height%22%3A%2236%22%2C%22width%22%3A%2236%22%2C%22widthPercent%22%3A100%2C%22isResponsive%22%3Afalse%2C%22classes%22%3A%7B%22sg-image%22%3A1%7D%7D"><a href="${
              process.env.REACT_APP_BASE_URL
            }/profile/notification"><img border="0" class="max-width" height="36" src="https://marketing-image-production.s3.amazonaws.com/uploads/a3fd383f900edd0c1c3458a1eaffa51d188a10305a8227e495be85bcf44c03725bb1adc0f20de940fa37ebbad358030ac76721582dfd7de82f433a7c97287f82.png" style="color: rgb(0, 0, 0); text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px;" width="36"></a></span></span></span></td>
						<td style="font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; vertical-align: top; border-collapse: collapse;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;"><span style="color: rgb(105, 105, 105); font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">&nbsp;How to </span><span style="font-weight: 600; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; color: rgb(0, 0, 0);">set notification preferences</span><span style="color: rgb(105, 105, 105); font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">.</span></span></span></td>
					</tr>
				</tbody>
			</table>

<div style="color: rgb(34, 34, 34); font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: small; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; padding-right: 15px; padding-bottom: 0px; padding-left: 15px;">
<div class="m_-5886274118951177444video-block" style="max-width: 570px; min-width: 320px;">&nbsp;</div>
        </div></td>
      </tr>
    </tbody></table></div>
        </td>
      </tr>
    </table>
    <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
      <tr>
        <td style="font-size:6px;line-height:10px;padding:0px 0px 0px 0px;" valign="top" align="center">
          <img class="max-width" border="0" style="display:block;color:#000000;text-decoration:none;font-family:Helvetica, arial, sans-serif;font-size:16px;max-width:100% !important;width:100%;height:auto !important;" src="https://marketing-image-production.s3.amazonaws.com/uploads/3a78d55ccf73c19945e754319f86fe18674311901a995e98006309e777257f198c560bb893478cb8e129d4788ae1d35d02652db94ff8ed69eb70e00757a1f413.gif" alt="" width="600">
        </td>
      </tr>
    </table>
  
    <table class="module"
           role="module"
           data-type="spacer"
           border="0"
           cellpadding="0"
           cellspacing="0"
           width="100%"
           style="table-layout: fixed;">
      <tr>
        <td style="padding:0px 0px 30px 0px;"
            role="module-content"
            bgcolor="">
        </td>
      </tr>
    </table>
  <table border="0" cellPadding="0" cellSpacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout:fixed" width="100%"><tbody><tr><td align="center" class="outer-td" style="padding:00px 0px 0px 0px"><table border="0" cellPadding="0" cellSpacing="0" class="button-css__deep-table___2OZyb wrapper-mobile" style="text-align:center"><tbody><tr><td align="center" bgcolor="#13532f" class="inner-td" style="border-radius:6px;font-size:16px;text-align:center;background-color:inherit"><a style="background-color:#13532f;border:1px solid #333333;border-color:#ffffff;border-radius:6px;border-width:1px;color:#ffffff;display:inline-block;font-family:arial,helvetica,sans-serif;font-size:16px;font-weight:normal;letter-spacing:1px;line-height:16px;padding:28px 18px 28px 18px;text-align:center;text-decoration:none" href="${
    process.env.REACT_APP_BASE_URL
  }" target="_blank">Get Started on Dexter   👉</a></td></tr></tbody></table></td></tr></tbody></table>
    <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
      <tr>
        <td style="padding:18px 0px 18px 0px;line-height:22px;text-align:inherit;"
            height="100%"
            valign="top"
            bgcolor="">
            <div align="center" class="m_-5886274118951177444button-container m_-5886274118951177444center" style="padding: 10px 20px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: small; color: rgb(34, 34, 34);">
<p style="margin-bottom: 0px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 18px; color: rgb(85, 85, 85); text-align: left;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;">More questions? Read through our site <a href="https://www.dexterexperts.com/faq">FAQs</a> and please&nbsp;feel free to respond to this email with any questions that may come to mind.<br style="line-height: inherit;">
<br style="line-height: inherit;">
Cheers,</span></span></p>

<p style="margin-bottom: 0px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; font-family: &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 18px; color: rgb(85, 85, 85); text-align: left;"><span style="font-size:18px;"><span style="font-family:arial,helvetica,sans-serif;">Your Dexter Team</span></span></p>
</div>
        </td>
      </tr>
    </table>
  <div data-role="module-unsubscribe" class="module unsubscribe-css__unsubscribe___2CDlR" role="module" data-type="unsubscribe" style="color:#444444;font-size:12px;line-height:20px;padding:16px 16px 16px 16px;text-align:center"><div class="Unsubscribe--addressLine"><p class="Unsubscribe--senderName" style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px">Dexter</p><p style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px"><span class="Unsubscribe--senderAddress">10203 Santa Monica Blvd Suite 400</span>, <span class="Unsubscribe--senderCity">Los Angeles</span>, <span class="Unsubscribe--senderState">CA</span> <span class="Unsubscribe--senderZip">90067</span> </p></div><p style="font-family:Arial,Helvetica, sans-serif;font-size:12px;line-height:20px"><a class="Unsubscribe--unsubscribeLink" href="# ">Unsubscribe</a> - <a class="Unsubscribe--unsubscribePreferences" href="# ">Unsubscribe Preferences</a></p></div>
                              </td>
                            </tr>
                          </table>
                          <!--[if mso]>
                          </td></tr></table>
                          </center>
                          <![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </center>
  </body>
</html>
`
