import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Form } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import { CONNECT_STRIPE_MUTATION } from '../../graphql/stripe'
import DataContext from '../../contexts/DataContext'
import Loading from '../Loading'
import { SUPPORTED_COUNTRIES, EXPRESS_COUNTRIES } from '../../constants/stripe'
import {
  COUNTRIES_TO_CODES,
  CODES_TO_COUNTRIES,
} from '../../constants/countries'
import MessageContext from '../../contexts/MessageContext'
const DefaultPanel = () => {
  const { advisor } = useContext(DataContext)
  const { setAlertMsg } = useContext(MessageContext)

  const [basedCountry, setBasedCountry] = useState(advisor.expert.country)

  const [connectStripe] = useMutation(CONNECT_STRIPE_MUTATION, {
    onCompleted: ({ connectExpertToStripe }) => {
      if (!connectExpertToStripe.success) {
        let alertTitle = 'Failed to Connect to the Stripe Account'
        let alertContent = `Connecting to the Stripe account failed due to: ${connectExpertToStripe.error} Please try again later or contact our support team at help@dexterexperts.com.`

        if (connectExpertToStripe.error === 'Duplicate Stripe ID') {
          alertTitle += ': Duplicate Stripe ID'
          alertContent =
            'Connecting to the Stripe account failed because it is already in use by another account. Each Stripe account can only be linked to one account at a time. If you believe this is an error or need further assistance, please contact our support team at help@dexterexperts.com for help.'
        }

        setAlertMsg({
          title: alertTitle,
          content: alertContent,
          onClose: () => (window.location.href = '/payment'),
        })
      } else {
        MixpanelTracker.trackConnectToStripe()
        window.location.href = '/payment'
      }
    },
  })

  useEffect(() => {
    if (getUrlVars()['code']) {
      connectStripe({
        variables: {
          code: getUrlVars()['code'],
        },
      })
    }
  }, [connectStripe])

  const getUrlVars = () => {
    let vars = {}
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value
    })
    return vars
  }

  const connectWithStripe = (type) => {
    if (type === 'express') {
      window.open(
        `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_BASE_URL}/payment&client_id=${process.env.REACT_APP_STRIPE_CLIENT_KEY}&stripe_user[email]=${advisor.email}`,
        '_blank'
      )
    } else {
      window.open(
        `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_KEY}&scope=read_write`,
        '_blank'
      )
    }
  }

  const supportedCountries = SUPPORTED_COUNTRIES.map((country) => country.name)
  const supported = supportedCountries.includes(basedCountry)

  if (getUrlVars()['code']) return <Loading />

  return (
    <div>
      <div className="bank-status">
        <h1>
          {supported
            ? 'You are not connected to Stripe'
            : `${basedCountry} is not supported by Stripe`}
        </h1>
        <h3 style={{ marginTop: 0 }}>
          {supported ? (
            'Set up payments with Stripe and start getting paid for your insights'
          ) : (
            <div>
              Please reach out to us at{' '}
              <a href="mailto:help@dexterexperts.com" className="contact-link">
                help@dexterexperts.com
              </a>{' '}
              to explore other payment options.
            </div>
          )}
        </h3>
        <h3>
          {supported
            ? null
            : `Do you have an account outside of ${basedCountry}?`}
        </h3>
      </div>
      <div className="bank-section">
        <div className="connect-section">
          <div className="inline field">
            <h3>
              {supported
                ? 'Select the country you are located in'
                : 'See if you can connect to Stripe using that account '}
            </h3>
            <br />
            <Form.Dropdown
              placeholder="Select Country"
              fluid
              search
              selection
              value={COUNTRIES_TO_CODES[basedCountry]}
              options={SUPPORTED_COUNTRIES.map((country) => ({
                key: country.code,
                value: country.code,
                text: country.name,
                flag: country.code,
              }))}
              onChange={(e, { value }) =>
                setBasedCountry(CODES_TO_COUNTRIES[value])
              }
            />
            <br />
            <br />
            {supported ? (
              EXPRESS_COUNTRIES.includes(basedCountry) ? (
                <button
                  onClick={() => connectWithStripe('express')}
                  className="ui primary button stripe-connect-btn"
                >
                  Connect with Stripe
                </button>
              ) : (
                <button
                  onClick={() => connectWithStripe('standard')}
                  className="ui primary button stripe-connect-btn"
                >
                  Connect with Stripe
                </button>
              )
            ) : null}
          </div>
          <div className="ui icon message stripe-message">
            <div className="content">
              We process payments via{' '}
              <a
                href="https://stripe.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>
              , a global online payments processor trusted by Amazon, Google,
              Microsoft and thousands of other businesses. Stripe is extremely
              safe and secure, and you can read more about Stripe in the FAQ
              below. Please click the “Connect with Stripe” button to be taken
              to Stripe’s account set up page.
            </div>
            <img
              aria-hidden="true"
              src={`${process.env.REACT_APP_AWS_S3_URL}/stripe.png`}
              alt="stripe"
              id="stripe-message-icon"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultPanel
