import React from 'react'

const Link = (props) => {
  return (
    <a
      href={props.link}
      target="_blank"
      rel="noopener noreferrer"
      data-toggle="tooltip"
      title={props.title}
    >
      {props.children}
    </a>
  )
}
export default Link
