import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../components/Loading'
import ErrorMessage from '../components/ErrorMessage'

const AuthContext = React.createContext({ token: null })
export const AuthProvider = ({ children }) => {
  const {
    isLoading,
    isAuthenticated,
    getIdTokenClaims,
    error,
    loginWithRedirect,
    loginWithPopup,
    logout,
    user,
  } = useAuth0()
  // A user will have a token and Auth0 profile after signing up with Auth0,
  // even if their email is not verified
  const [token, setToken] = useState(null)
  const isPublic = sessionStorage.getItem('publicProject')

  if (isLoading) {
    return <Loading />
  }

  if (isAuthenticated) {
    getIdTokenClaims().then((t) => {
      // To test this, manually set the id token expiration time on Auth0 (DO NOT do this on Production environment)
      const expirationDate = new Date(t.exp * 1000)
      const today = new Date()
      if (expirationDate > today) {
        setToken(t.__raw)
      }
    })
  }

  const signout = () =>
    logout({
      logoutParams: {
        returnTo: isPublic
          ? `${window.location.origin}/landing/authenticate?state=login`
          : window.location.origin,
      },
    })

  return (
    <AuthContext.Provider
      value={{
        token,
        auth0User: user,
        loginWithRedirect,
        logout: signout,
        loginWithPopup,
      }}
    >
      {error && <ErrorMessage content={error.message} onClose={signout} />}
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
