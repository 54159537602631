import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Form, Header, Input, Dropdown } from 'semantic-ui-react'
import CountryDropdown from '../CountryDropdown'
import TimeZone from '../Schedule/TimeZone'
import DataContext from '../../contexts/DataContext'

const General = (props, ref) => {
  const { advisor } = useContext(DataContext)

  const [firstName, setFirstName] = useState(advisor.firstName || '')
  const [lastName, setLastName] = useState(advisor.lastName || '')
  const [salutation, setSalutation] = useState(
    (advisor.expert && advisor.expert.salutation) || ''
  )
  const [phone, setPhone] = useState(
    (advisor.expert && advisor.expert.phone) || ''
  )
  const [country, setCountry] = useState(
    (advisor.expert && advisor.expert.country) || ''
  )
  useImperativeHandle(ref, () => {
    return {
      state: {
        firstName,
        lastName,
        salutation,
        phone,
        country,
      },
    }
  })

  return (
    <div id="general-section">
      {!props.skipHeader && <Header as="h4">General Information</Header>}
      <div className="content">
        <Form>
          <Form.Group>
            <Form.Field required width={7}>
              <label>First Name</label>
              <Input
                placeholder="First Name"
                value={firstName}
                onChange={(e, { value }) => setFirstName(value)}
              />
            </Form.Field>
            <Form.Field required width={7}>
              <label>Last Name</label>
              <Input
                placeholder="Last Name"
                value={lastName}
                onChange={(e, { value }) => setLastName(value)}
              />
            </Form.Field>
            <Form.Field width={3}>
              <label>Salutation</label>
              <Dropdown
                fluid
                selection
                value={salutation}
                options={[
                  { key: 'Mr.', text: 'Mr.', value: 'Mr.' },
                  { key: 'Ms.', text: 'Ms.', value: 'Ms.' },
                  { key: 'Dr.', text: 'Dr.', value: 'Dr.' },
                  { key: 'Mrs.', text: 'Mrs.', value: 'Mrs.' },
                  { key: 'Miss.', text: 'Miss.', value: 'Miss.' },
                  { key: 'Prof.', text: 'Prof.', value: 'Prof.' },
                ]}
                onChange={(e, { value }) => setSalutation(value)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field required>
            <label>Country</label>
            <CountryDropdown defaultCountry={country} setCountry={setCountry} />
          </Form.Field>
          {!props.skipTimeZone && (
            <Form.Field>
              <label>Time zone</label>
              <TimeZone />
            </Form.Field>
          )}
          {!props.isRegister && (
            <Form.Field>
              <label>Phone</label>
              <Input
                placeholder="Phone No."
                value={phone}
                onChange={(e, { value }) => setPhone(value)}
              />
            </Form.Field>
          )}
        </Form>
      </div>
    </div>
  )
}

export default forwardRef(General)
