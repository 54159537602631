import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

class MixpanelTracker {
  static trackRegisterGeneralStep(data) {
    mixpanel.track('Registeration Basics Step', {
      'Expert First Name': data.firstName,
      'Expert Last Name': data.lastName,
      'Expert Salutation': data.salutation,
      'Expert Country': data.country,
    })
  }

  static trackRegisterProfessionalStep(data) {
    mixpanel.track('Registeration Professional Step', {
      'Expert Title': data.title,
      'Expert Linkedin': data.linkedinProfile,
      'Expert Organization': data.organizationName,
      'Expert Hourly Rate': data.hourlyRate,
    })
  }

  static trackRegisterExpertiseStep(data) {
    const expertTags = [
      ...data.functions,
      ...data.medicalSpecialties,
      ...data.technologyTags,
    ]
    mixpanel.track('Registeration Tags Step', {
      'Expert Profile Description': data.description,
      'Expert Tags': expertTags,
    })
  }

  static trackRegisterEEOStep(data) {
    mixpanel.track('Registeration EEO Step', {
      'Expert Gender': data.gender,
      'Expert Year Of Birth': data.yearOfBirth,
      'Expert Education': data.education,
      'Expert Ethnicity': data.ethnicity,
      'Expert Language': data.language,
      'Expert Veteran Status': data.veteranStatus,
      'Expert Disability Status': data.disabilityStatus,
    })
  }

  static trackAcceptInvite(project) {
    mixpanel.track('Accept Project', { 'Project Name': project.title })
  }

  static trackDeclineInvite(project) {
    mixpanel.track('Declined Project', { 'Project Name': project.title })
  }

  static trackViewStripe() {
    mixpanel.track('View Stripe Dashboard')
  }

  static trackConnectToStripe() {
    mixpanel.track('Connected to Stripe')
  }

  static trackDisconnectFromStripe() {
    mixpanel.track('Disconnected From Stripe')
  }

  static trackSetEmailNotifications(
    projectNotify,
    reminderNotify,
    paymentNotify
  ) {
    mixpanel.track('Set Email Notifications', {
      'Project Notify': projectNotify,
      'Reminder Notify': reminderNotify,
      'Payment Notify': paymentNotify,
    })
  }

  static trackRateProject(project, rating) {
    mixpanel.track('Rate Project', {
      'Project Name': project.title,
      'Project Rate': rating,
    })
  }

  static trackViewPublicProjectLanding(
    user = null,
    project,
    hasResponded = false
  ) {
    mixpanel.track('View Public Project Landing', {
      'Authenticated Expert': user !== null,
      'Expert ID': user?.id,
      'Expert Name': user?.fullName,
      'Expert Email': user?.email,
      'Has Responded': hasResponded,
      'Project Name': project.title,
      'Project Type': project.type,
      'Project Offer Rate': project.offerRate,
      'Project Qualification Questions': project.qualificationQuestions?.map(
        (q) => `${q.question} ${q.questionType}`
      ),
      '# of Project Qualification Questions':
        project.qualificationQuestions?.length,
    })
  }

  static trackDeclinePublicProject() {
    mixpanel.track('Decline Public Project')
  }

  // The status is either "Accepted" or "Declined"
  static trackPublicProjectLandingSignUp(status) {
    mixpanel.track('Sign up from Public Project Landing', {
      'Project Reponse Status': status,
    })
  }

  static trackViewPublicProjectRegister(user, hasResponded = false) {
    mixpanel.track('View Public Project Register', {
      'Has Responded': hasResponded,
      'Existing Expert': user.expert !== null,
      'Expert ID': user.id,
      'Expert Email': user.email,
      'Expert Name': user.fullName,
      'Expert Title': user.expert?.title,
    })
  }

  static trackViewPublicProjectSchedule() {
    mixpanel.track('View Public Project Schedule')
  }

  static trackViewPublicProjectRegisterForm() {
    mixpanel.track('View Public Project Register Form')
  }

  static trackPublicProjectLandingCreateJob(user) {
    mixpanel.track('Create Job from Public Project Landing', {
      'Expert ID': user.id,
      'Expert Email': user.email,
      'Expert Title': user.expert.title,
      'Expert First Name': user.expert.first_name,
      'Expert Last Name': user.expert.last_name,
      'Expert Organization': user.expert.organization.name,
      'Expert Organization Type': user.expert.organization.type,
      'Expert Country': user.expert.country,
    })
  }
}

export const mixpanelClient = mixpanel

export default MixpanelTracker
