import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Loader, Dimmer } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'

import DataContext from '../../contexts/DataContext'
import MessageContext from '../../contexts/MessageContext'

import { SEND_EMAIL_MUTATION } from '../../graphql/sendgrid'
import { CREATE_EXPERT } from '../../graphql/expert'
import { LOAD_ME_QUERY, UPDATE_USER } from '../../graphql/user'
import { SCHEDULE_MEETING } from '../../graphql/schedule'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import PublicProjectSchedule from '../../components/PublicProject/PublicProjectSchedule'
import PublicProjectRegisterForm from '../../components/PublicProject/PublicProjectRegisterForm'
import { getWelcomeEmail } from '../../components/Emails/WelcomeEmail'
import NotFoundPage from '../NotFoundPage'
import ExistingResponseAlert from '../../components/PublicProject/ExistingResponseAlert'

const PublicProjectRegister = () => {
  const history = useHistory()
  const { advisor, activeProjects } = useContext(DataContext)
  const { setAlertMsg } = useContext(MessageContext)

  const publicProject = JSON.parse(sessionStorage.getItem('publicProject'))
  const qualificationResponses = JSON.parse(
    sessionStorage.getItem('qualification')
  )

  const isExistingUser = advisor.expert !== null

  const existingInvite = advisor.jobs.find(
    (job) => job.project.id === publicProject.id
  )
  const hasResponded =
    existingInvite &&
    !['Pending Scheduling', 'Client Reschedule'].includes(
      existingInvite.overall_status
    )

  useEffect(() => {
    if (advisor) {
      MixpanelTracker.trackViewPublicProjectRegister(advisor, hasResponded)
    }
  }, [advisor, hasResponded])

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [meetingAt, setMeetingAt] = useState(null)
  const [step, setStep] = useState(
    hasResponded ? 2 : publicProject?.type !== 'Interview' ? 1 : 0
  )

  const [createExpert] = useMutation(CREATE_EXPERT)
  const [updateUser] = useMutation(UPDATE_USER, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: LOAD_ME_QUERY,
        data: { me: data.updateUser },
      })
    },
  })
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

  const [scheduleMeeting] = useMutation(SCHEDULE_MEETING, {
    refetchQueries: [{ query: LOAD_ME_QUERY }],
  })

  if (!publicProject || !qualificationResponses)
    return (
      <NotFoundPage message="If you received an invitation to a Dexter project, please go back to the invitation link and start over." />
    )

  const removeSessionStorage = () => {
    sessionStorage.removeItem('publicProject')
    sessionStorage.removeItem('qualification')
  }

  const onComplete = ({
    job = null,
    type = 'Interview',
    showNextStepsModal = false,
    showPreInterviewModal = false,
    error = null,
  }) => {
    if (error) {
      setAlertMsg({
        title: error.title,
        content: error.content,
      })
    }
    const state = {
      type,
      showNextStepsModal,
      showPreInterviewModal,
      job,
    }
    history.push('/projects/active', state)

    removeSessionStorage()
  }

  const handleNext = () => {
    setMessage(null)
    if (!meetingAt)
      return setMessage('You must select a time slot for your meeting')
    setStep(1)
  }

  const register = async (generalData, professionalData) => {
    try {
      setLoading(true)
      if (!isExistingUser) {
        const { firstName, lastName, salutation, country } = generalData
        const { linkedinProfile, title, organization } = professionalData
        await createExpert({
          variables: {
            profile: {
              first_name: firstName,
              last_name: lastName,
              salutation,
              country,
              title,
              organization,
              linkedin_profile: linkedinProfile,
              hourlyRate: parseInt(publicProject.compensationType.params[0]),
            },
            notifications: {
              status: 'Active',
              reminder_notify: true,
              payment_notify: true,
            },
          },
          update: () => {
            updateUser({
              variables: { input: { firstName, lastName } },
            })
            sendEmail({
              variables: {
                input: {
                  from: {
                    email: 'help@dexterexperts.com',
                    name: 'Dexter Expert Network',
                  },
                  to: { email: advisor.email },
                  subject: 'Welcome to Dexter | Thanks for Joining!',
                  text: 'Welcome to Dexter and Thanks for Joining',
                  html: getWelcomeEmail(`${firstName} ${lastName}`),
                },
              },
            })
          },
        })
      }
      const jobInput = {
        invite_status: 'Invited',
        invitedAt: new Date(),
        ...(qualificationResponses.length > 0 && {
          qualificationResponses,
        }),
      }
      if (publicProject.type === 'Survey') {
        jobInput.response_status = 'Accepted'
        jobInput.overall_status = 'Scheduled'
        jobInput.responseAt = new Date()
      }
      await updateJob({
        variables: {
          userId: advisor.id,
          projectId: publicProject.id,
          job: jobInput,
        },
        update: (cache, { data: { updateJob } }) => {
          MixpanelTracker.trackPublicProjectLandingCreateJob(updateJob.user)
          if (publicProject.type === 'Interview') {
            scheduleMeeting({
              variables: {
                jobId: updateJob.id,
                startTime: meetingAt,
              },
              update: (cache, { data: { scheduleMeeting } }) => {
                setLoading(false)
                onComplete({
                  type: publicProject.type,
                  job: scheduleMeeting,
                  showNextStepsModal: activeProjects.length === 0,
                  showPreInterviewModal:
                    scheduleMeeting.project.preInterviewLink !== null,
                })
              },
            })
          } else {
            setLoading(false)
            onComplete({
              type: publicProject.type,
              job: updateJob.user.jobs.find((el) => el.id === updateJob.id),
              showNextStepsModal: activeProjects.length === 0,
            })
          }
        },
      })
    } catch (error) {
      setLoading(false)
      if (
        error.message ===
          'This meeting could not be scheduled due to insufficient credits' ||
        error.message === 'No more invites left'
      ) {
        return onComplete({
          error: {
            title: 'Failed to accept the invitation',
            content:
              'This project is currently on hold. Please try again in 24 hours and contact us at help@dexterexperts.com if the issue persists.',
          },
        })
      }

      return onComplete({
        error: {
          title: 'Something went wrong. Failed to accept the invitation',
          content:
            'Please try again and contact us at help@dexterexperts.com if the issue persists.',
        },
      })
    }
  }

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            {loading && (
              <Dimmer inverted active>
                <Loader size="large">Loading</Loader>
              </Dimmer>
            )}
            <PublicProjectRegisterForm
              meetingAt={meetingAt}
              timeZone={advisor.timeZone}
              project={{ ...publicProject }}
              qualificationResponses={qualificationResponses}
              handleBack={() => setStep(0)}
              handleSubmit={register}
              isExistingUser={isExistingUser}
            />
          </>
        )
      case 2:
        return (
          <ExistingResponseAlert
            project={publicProject}
            onClose={removeSessionStorage}
          />
        )

      default:
        return (
          <PublicProjectSchedule
            title={publicProject.title}
            projectId={publicProject.id}
            meetingAt={meetingAt}
            setMeetingTime={setMeetingAt}
            handleNext={handleNext}
            message={message}
          />
        )
    }
  }

  return renderContent()
}

export default PublicProjectRegister
