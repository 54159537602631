import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Form, Button, Message, Confirm } from 'semantic-ui-react'
import AuthContext from '../../contexts/AuthContext'
import DataContext from '../../contexts/DataContext'
import { UPDATE_EMAIL } from '../../graphql/user'

const Account = () => {
  const { logout, auth0User } = useContext(AuthContext)
  const { advisor } = useContext(DataContext)
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState(advisor.email)

  const [updateEmail, { error }] = useMutation(UPDATE_EMAIL, {
    onError: () => setOpen(false),
    onCompleted: logout,
  })

  const isSocialAccount = !auth0User.sub.startsWith('auth0|')
  const MESSAGE = {
    header: isSocialAccount
      ? 'If you signed up with a social account, email updates are not supported on this page.'
      : 'To update your email, please be aware of the following important details:',
    content: isSocialAccount
      ? 'If you wish to update your email, please contact us at help@dexterexperts.com for support.'
      : 'You will be automatically logged out of your account. Upon logging back in, you will be prompted to verify your new email address for security purposes.',
  }
  const getCustomErrorMessage = (error) => {
    if (
      error.message.includes("Object didn't pass validation for format email")
    ) {
      return 'Please enter a valid email address.'
    }
    return error.message
  }

  return (
    <div>
      <div className="form-section">
        <Form>
          <h4>Email Address</h4>
          <Form.Input
            error={error ? { content: getCustomErrorMessage(error) } : false}
            value={email}
            disabled={isSocialAccount}
            onChange={(e, { value }) => setEmail(value)}
          />
        </Form>
        <Message warning>
          <Message.Header>{MESSAGE.header}</Message.Header>
          <p>{MESSAGE.content}</p>
        </Message>
      </div>
      <Button
        negative
        floated="right"
        content="Update"
        disabled={isSocialAccount}
        onClick={() => setOpen(true)}
        className="profile-btn-section"
      />
      <Confirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() =>
          updateEmail({
            variables: { email },
          })
        }
        content="Are you sure you wish to update your email address?"
      />
    </div>
  )
}

export default Account
