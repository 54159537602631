import React, { useContext } from 'react'
import { Button, Header } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import AuthContext from '../../contexts/AuthContext'

const PublicProjectUnqualified = () => {
  const { loginWithRedirect } = useContext(AuthContext)

  return (
    <div id="public-project-landing">
      <div id="public-project-register">
        <Header sub>Join Dexter</Header>
        <Header size="medium">
          We&apos;re sorry, but this project isn&apos;t the right fit for you.
        </Header>
        <div>
          Don&apos;t miss out on other exciting consulting opportunities
          perfectly tailored to your expertise. Join Dexter now to start
          receiving invitations to paid projects.
        </div>
        <div style={{ marginTop: '1rem' }}>
          At Dexter, we collaborate with professionals from diverse backgrounds,
          including pharma, academia, and healthcare, across various precision
          medicine fields such as Oncology, Pathology, Liquid Biopsy,
          Single-Cell, Sequencing, and more.
          <div>
            <div id="public-project-btn">
              <Button
                primary
                onClick={() => {
                  MixpanelTracker.trackPublicProjectLandingSignUp('Declined')
                  loginWithRedirect({
                    appState: {
                      returnTo: window.location.pathname,
                    },
                  })
                }}
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicProjectUnqualified
