export const getDeclineNoticeEmail = (
  full_name,
  project_title,
  recom_name,
  recom_email
) => `
<p>This is an automated email to notify you ${full_name} for ${project_title} has declined your invitation.</p><br />
	   ${
       recom_name && recom_email
         ? `<div><p>This expert has also submitted a referal for this project: </p><p>Name: ${recom_name}</p><p>Email: ${recom_email}</p></div>`
         : ''
     }
	   <p>Log in to Dexter: </p>
	   <p><a href="${process.env.REACT_APP_ADMIN_BASE_URL}" target="_blank">
     ${process.env.REACT_APP_ADMIN_BASE_URL}</a></p>
`
