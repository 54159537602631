import React, { useState } from 'react'
import { Header, Accordion } from 'semantic-ui-react'
import './QuestionItem.css'

const QuestionItem = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="question_item">
      <Accordion>
        <Accordion.Title active={open} onClick={() => setOpen(!open)}>
          <Header as="h4" dividing>
            {props.headerText}
          </Header>
        </Accordion.Title>
        <Accordion.Content active={open} className="answer_body">
          <div dangerouslySetInnerHTML={{ __html: props.children[0] }} />
          {props.children[1]}
        </Accordion.Content>
      </Accordion>
    </div>
  )
}

export default QuestionItem
