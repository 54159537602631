import React, { useContext } from 'react'
import { Button } from 'semantic-ui-react'
import MessageContext from '../../../../contexts/MessageContext'

const StartSurveyButton = ({ surveyLink }) => {
  const { setAlertMsg } = useContext(MessageContext)
  return (
    <Button
      primary
      onClick={() => {
        if (surveyLink) {
          window.open(surveyLink, '_blank')
        } else {
          setAlertMsg({
            content:
              'We are creating your survey. Please check again by refreshing the page in few minutes.',
          })
        }
      }}
    >
      Start the Survey
    </Button>
  )
}

export default StartSurveyButton
