import React from 'react'

import { Grid, Header } from 'semantic-ui-react'
import GridItem from './GridItem'
import Image from './Image'

import './GridContainer.css'

const GridContainer = () => {
  return (
    <div className="container">
      <Header as="h2" content="" subheader="Businesses that use Stripe" />
      <Grid stackable columns={3}>
        <GridItem link="//www.twitter.com" title="Twitter">
          <Image
            source="//www.donorhut.com/images/stripe/twitter.png"
            alt="Twitter Logo"
          />
        </GridItem>
        <GridItem link="//www.salesforce.com" title="Salesforce">
          <Image
            source="//www.donorhut.com/images/stripe/salesforce.png"
            alt="Salesforce Logo"
          />
        </GridItem>
        <GridItem link="//www.ted.com" title="Ted">
          <Image
            source="//www.donorhut.com/images/stripe/ted.png"
            alt="Ted Logo"
          />
        </GridItem>
        <GridItem link="//www.shopify.com" title="Shopify">
          <Image
            source="//www.donorhut.com/images/stripe/shopify.png"
            alt="ShopifyLogo"
          />
        </GridItem>
        <GridItem link="//www.fitbit.com" title="Fitbit">
          <Image
            source="//www.donorhut.com/images/stripe/fitbit.png"
            alt="Fitbit Logo"
          />
        </GridItem>
        <GridItem link="//www.theguardian.com" title="The Guardian">
          <Image
            source="//www.donorhut.com/images/stripe/theguardian.png"
            alt="The Guardian Logo"
          />
        </GridItem>
      </Grid>
    </div>
  )
}

export default GridContainer
