import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, Grid, Header } from 'semantic-ui-react'
import Spinner from 'react-spinkit'
import ProjectCard from './ProjectCard'
import DataContext from '../../contexts/DataContext'
import { onInfiniteScroll } from '../../utils/helpers'

const ProjectList = ({ projects, category }) => {
  const { advisor } = useContext(DataContext)
  const [numberOfCards, setNumberOfCards] = useState(5)

  const renderNoProjects = () => (
    <Grid container stackable className="not-found">
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Header as="h1">No {category} projects yet</Header>
          {category !== 'past' && (
            <Header.Subheader>
              Come back after some time! Try adding more tags to your{' '}
              <Link to="/profile">Profile</Link> to receive more project
              opportunities!
            </Header.Subheader>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )

  const renderProjects = () => (
    <Card.Group>
      <Grid stackable>
        {projects.slice(0, numberOfCards).map((project) => {
          const job = advisor.jobs.filter((job) => {
            return job.project && job.project.id === project.id
          })[0]

          return (
            <Grid.Column className="pending-cards" key={project.id}>
              <ProjectCard category={category} project={project} job={job} />
            </Grid.Column>
          )
        })}
      </Grid>
    </Card.Group>
  )

  onInfiniteScroll(() => setNumberOfCards(numberOfCards + 10))

  return (
    <div className="pending-page">
      {projects.length === 0 ? renderNoProjects() : renderProjects()}
      <br />
      {numberOfCards < projects.length && (
        <Spinner name="circle" style={{ margin: 'auto' }} />
      )}
    </div>
  )
}

export default ProjectList
