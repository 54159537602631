import React, { useState } from 'react'
import { List, Button, Input, Popup } from 'semantic-ui-react'
const MeetingInvite = ({ meetingInvite, meetingLink }) => {
  const [copyPopup, setCopyPopup] = useState('Copy link')
  const onCopy = () => {
    const copyText = document.getElementById('zoom-link')
    copyText.select()
    // For mobile devices
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setCopyPopup('Copied !')
  }
  return (
    <>
      <List.Item>
        <List.Content>
          <List.Header>Meeting information</List.Header>
          <List.Description>
            <pre>{meetingInvite}</pre>
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content id="zoom-info-btns">
          <Button
            className="primary-btn"
            primary
            onClick={() => {
              window.open(meetingLink, '_blank')
            }}
          >
            Join Zoom Meeting
          </Button>

          <Input
            label={
              <Popup
                content={copyPopup}
                on="click"
                pinned
                trigger={
                  <Button className="basic-btn" onClick={onCopy} secondary>
                    Copy
                  </Button>
                }
              />
            }
            labelPosition="right"
            readOnly
            id="zoom-link"
            value={meetingLink}
          />
        </List.Content>
      </List.Item>
    </>
  )
}

export default MeetingInvite
