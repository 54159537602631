import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Container, Form, Header, Image, Button } from 'semantic-ui-react'
import { UPDATE_EXPERT } from '../graphql/expert'
import DataContext from '../contexts/DataContext'

const Referral = () => {
  const history = useHistory()
  const { advisor } = useContext(DataContext)

  const [updateExpert] = useMutation(UPDATE_EXPERT)

  const [emails, setEmails] = useState([''])

  const insertEmail = (email, index) => {
    const tempEmails = emails.slice()
    tempEmails[index] = email
    setEmails(tempEmails)
  }

  const recordEmails = () => {
    const recordingEmails = emails.filter((email) => email.length > 0)

    if (recordingEmails.length === 0) return

    updateExpert({
      variables: {
        input: {
          referrals: advisor.expert.referrals.concat({
            date: new Date(),
            emails: Array.from(new Set(recordingEmails)),
          }),
        },
      },
      onCompleted: () => {
        alert('Thank you for your response')
        history.replace('/dashboard')
      },
    })
  }

  return (
    <Container textAlign="center" style={{ marginTop: '5%' }}>
      <Header as="h1">
        THANKS FOR THE REVIEW; DON&apos;T KEEP US A SECRET
      </Header>
      <Header as="h2">Spread the word and reward yourself</Header>
      <Image
        src={`${process.env.REACT_APP_AWS_S3_URL}/Dexter_Referral.gif`}
        size="big"
        verticalAlign="middle"
      />
      <Form style={{ width: '60%', margin: 'auto', marginTop: '3%' }}>
        {emails.map((email, index) => (
          <Form.Field key={index} required>
            <label style={{ textAlign: 'left', fontSize: '18px' }}>Email</label>
            <input onChange={(e) => insertEmail(e.target.value, index)} />
          </Form.Field>
        ))}
      </Form>
      <Button
        positive
        circular
        icon="plus"
        style={{ marginTop: '3%' }}
        onClick={() => setEmails(emails.concat(''))}
      />
      <Button
        negative
        circular
        icon="minus"
        style={{ display: 'inline' }}
        onClick={() => setEmails(emails.slice(0, emails.length - 1))}
      />
      <br />
      <Button primary style={{ marginTop: '3%' }} onClick={recordEmails}>
        Submit
      </Button>
    </Container>
  )
}

export default Referral
