import React, { useContext } from 'react'
import { Icon, Header, Button } from 'semantic-ui-react'
import Dayz from 'dayz'
import moment from 'moment-timezone'
import DataContext from '../../../contexts/DataContext'
import 'dayz/dist/dayz.css'

const Calendar = ({ project, currentDate, setCurrentDate }) => {
  const { advisor } = useContext(DataContext)
  const EVENTS = new Dayz.EventsCollection(
    project.calendar
      .filter((calendarDay) =>
        calendarDay.entries.some((entry) => entry.status === 'Available')
      )
      .map((calendarDay) => {
        const availableTimeSlots = calendarDay.entries.filter(
          (entry) => entry.status === 'Available'
        )
        return {
          content: `${availableTimeSlots.length} slots`,
          range: moment.range(
            new Date(calendarDay.date),
            new Date(calendarDay.date)
          ),
        }
      })
  )

  const MONTHS = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const today = new Date()

  return (
    <>
      <div style={{ marginBottom: '3%' }}>
        {new Date(currentDate).getTime() > today.getTime() && (
          <Icon
            name="angle left"
            style={{ display: 'inline', cursor: 'pointer' }}
            onClick={() =>
              setCurrentDate(
                new Date(currentDate.setMonth(currentDate.getMonth() - 1))
              )
            }
          />
        )}
        <Header as="h5" style={{ display: 'inline', margin: '0 5% 0 5%' }}>
          {`${MONTHS[new Date(currentDate).getMonth()]}, ${new Date(
            currentDate
          ).getFullYear()}`}
        </Header>
        {new Date(currentDate).getTime() <
          new Date(today.setMonth(today.getMonth() + 11)).getTime() && (
          <Icon
            name="angle right"
            style={{ display: 'inline', cursor: 'pointer' }}
            onClick={() => {
              const currentMonth = currentDate.getMonth()
              const nextMonth = currentMonth + 1
              let newCurrentDate = new Date(currentDate.setMonth(nextMonth))
              // edge case (for example: 8/31 -> 10/1 due to no 9/31)
              if (newCurrentDate.getMonth() > nextMonth) {
                newCurrentDate = new Date(
                  newCurrentDate.setDate(newCurrentDate.getDate() - 1)
                )
              }
              setCurrentDate(newCurrentDate)
            }}
          />
        )}
        <Button
          basic
          size="tiny"
          floated="right"
          onClick={() => setCurrentDate(new Date())}
        >
          Today
        </Button>
      </div>
      <Dayz
        display="month"
        highlightDays={[
          new Date(moment.tz(currentDate, advisor.timeZone).format('llll')),
        ]}
        events={EVENTS}
        date={moment.tz(currentDate, advisor.timeZone)}
        onEventClick={(e, event) => setCurrentDate(new Date(event.start))}
        dayEventHandlers={{
          onClick: (ev, date) => setCurrentDate(new Date(date)),
        }}
      />
    </>
  )
}

export default Calendar
