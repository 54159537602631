import { gql } from '@apollo/client'
import { USER_MODEL } from './modelFields'

export const CREATE_EXPERT = gql`
  mutation CreateExpert(
    $profile: ExpertProfileInput!
    $notifications: ExpertNotificationInput!
  ) {
    createExpert(profile: $profile, notifications: $notifications) {
      id
    }
  }
`

export const UPDATE_EXPERT = gql`
  mutation UpdateExpert($input: ExpertProfileInput!) {
    updateExpert(input: $input) {
      id
      user {
        ${USER_MODEL}
      }
    }
  }
`
export const UPDATE_NOTIFICATIONS = gql`
  mutation UpdateNotifications($input: ExpertNotificationInput!) {
    updateNotifications(input: $input) {
      id
      user {
        id
        expert {
          id
          status
          reminder_notify
          payment_notify
        }
      }
    }
  }
`

export const UPLOAD_EXPERT_PICTURE_MUTATION = gql`
  mutation UploadExpertPicture($file: Upload!) {
    uploadExpertPicture(file: $file) {
      id
      profile_picture
    }
  }
`
