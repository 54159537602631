export const FAQS = [
  {
    question: 'What is Dexter?',
    answer:
      "Dexter is a curated community of precision medicine stakeholders across broad and niche fields. We're dedicated to our vision of accelerating innovation by fostering meaningful partnerships.",
  },
  {
    question: 'Why am I receiving this invitation?',
    answer:
      'We use various sources of publicly available data to find experts and key opinion leaders with relevant backgrounds and experiences. Based on your publication(s), Linkedin profile, presence at a conference, or other forms of public profile, your expertise and experience appears to be a good match for our consulting project. We wanted to extend an invitation to speak with us/participate in a survey.',
  },
  {
    question: 'Will I get paid for my consulting engagement?',
    answer:
      'We pride ourselves on paying our experts fair value for their time and knowledge. Consultations are typically a 30-60 minute video call with the client or completion of a 15-25 minute online survey. Individual projects will have its own honoraria rate specified in the project invite. You can also set your preferred hourly rate in your Dexter profile.',
  },
  {
    question: 'How and when will I get paid?',
    answer:
      'Payments are processed via a trusted payment platform, Stripe, within a few days of your consultation.',
  },
  {
    question: 'Other questions?',
    answer:
      'For any additional questions, please contact help@dexterexperts.com',
  },
]
