import React, { useContext } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import BoxLink from './BoxLink'
import DataContext from '../../contexts/DataContext'

const DashboardContent = () => {
  const { advisor, balance, activeProjects, pendingProjects, pastProjects } =
    useContext(DataContext)

  return (
    <Grid stackable className="dashboard-content">
      <Grid.Row className="title">
        <Header as="h1">
          {`Hello ${
            advisor.firstName && advisor.lastName
              ? `${advisor.firstName} ${advisor.lastName} `
              : 'There '
          }`}
          <Header.Subheader>
            Here is a summary of your recent activity
          </Header.Subheader>
        </Header>
      </Grid.Row>
      <Grid.Row columns={4} className="stats">
        <Grid.Column>
          <BoxLink
            to="/projects"
            title={pendingProjects.length}
            content="Pending Invites"
          />
        </Grid.Column>
        <Grid.Column>
          <BoxLink
            to="/projects/active"
            title={activeProjects.length}
            content="Active Projects"
          />
        </Grid.Column>
        <Grid.Column>
          <BoxLink
            to="/project/past"
            title={pastProjects.length}
            content="Completed Projects"
          />
        </Grid.Column>
        <Grid.Column>
          <BoxLink
            to="/payment"
            title={`$${parseFloat(balance).toFixed(2)}`}
            content="Earned"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DashboardContent
