import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Dropdown, Form, Header } from 'semantic-ui-react'
import { EEOOptions } from './EEOOptions'
import DataContext from '../../contexts/DataContext'

const EEO = (props, ref) => {
  const { advisor } = useContext(DataContext)

  const [gender, setGender] = useState(
    (advisor.expert && advisor.expert.gender) || ''
  )
  const [yearOfBirth, setYearOfBirth] = useState(
    (advisor.expert && advisor.expert.yearOfBirth) || ''
  )
  const [education, setEducation] = useState(
    (advisor.expert && advisor.expert.education) || ''
  )
  const [ethnicity, setEthnicity] = useState(
    (advisor.expert && advisor.expert.ethnicity) || ''
  )
  const [language, setLanguage] = useState(
    (advisor.expert && advisor.expert.language) || ''
  )
  const [veteranStatus, setVeteranStatus] = useState(
    (advisor.expert && advisor.expert.veteran_status) || ''
  )
  const [disabilityStatus, setDisabilityStatus] = useState(
    (advisor.expert && advisor.expert.disability_status) || ''
  )

  useImperativeHandle(ref, () => {
    return {
      state: {
        gender,
        yearOfBirth,
        education,
        ethnicity,
        language,
        veteranStatus,
        disabilityStatus,
      },
    }
  })

  return (
    <div id="eeo-section">
      <Header as="h4">
        U.S Equal Employment Opportunity Information (Completion is voluntary)
      </Header>
      {window.location.pathname === '/profile' && (
        <div className="content">
          We invite all individuals to voluntarily report their gender, year of
          birth, education, race/ethnicity, and primary lanage. We collect this
          information to help ensure the assessment is in compliance with
          non-discrimination laws and do not have an adverse impact on any
          particular race/ethnicity or gender. This information will remain
          confidential and will not be used for any purposes other than those
          stated above.
        </div>
      )}
      <br />
      <div className="content">
        <Form>
          <Form.Field>
            <label>What is your gender?</label>
            <Dropdown
              placeholder="Select your gender"
              fluid
              selection
              options={EEOOptions.gender}
              value={gender}
              onChange={(e, { value }) => setGender(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your year of birth?</label>
            <Dropdown
              placeholder="Enter your year of birth"
              fluid
              selection
              search
              value={yearOfBirth}
              options={EEOOptions.yearOfBirth}
              onChange={(e, { value }) => setYearOfBirth(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your educational archievement?</label>
            <Dropdown
              placeholder="Select your educational archievement"
              fluid
              selection
              options={EEOOptions.education}
              value={education}
              onChange={(e, { value }) => setEducation(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your primary ethnicity?</label>
            <Dropdown
              placeholder="Select your ethnicity"
              fluid
              search
              selection
              options={EEOOptions.ethnicity}
              value={ethnicity}
              onChange={(e, { value }) => setEthnicity(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your primary language?</label>
            <Dropdown
              placeholder="Select your language"
              fluid
              search
              selection
              options={EEOOptions.language}
              value={language}
              onChange={(e, { value }) => setLanguage(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your veteran status?</label>
            <Dropdown
              placeholder="Select a status"
              fluid
              selection
              options={EEOOptions.veteran_status}
              value={veteranStatus}
              onChange={(e, { value }) => setVeteranStatus(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>What is your disability status?</label>
            <Dropdown
              placeholder="Select a status"
              fluid
              selection
              options={EEOOptions.disability_status}
              value={disabilityStatus}
              onChange={(e, { value }) => setDisabilityStatus(value)}
            />
          </Form.Field>
        </Form>
      </div>
    </div>
  )
}

export default forwardRef(EEO)
