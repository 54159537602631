import React from 'react'
import { Confirm, Header, Button } from 'semantic-ui-react'

const SuccessConfirmation = ({ title, content, onClose }) => (
  <Confirm
    open
    closeIcon
    header="Success"
    content={
      <div style={{ padding: '1.5rem' }}>
        <Header>{title}</Header>
        <p>{content}</p>
      </div>
    }
    onConfirm={onClose}
    cancelButton={null}
    confirmButton={<Button primary>Done</Button>}
  />
)

export default SuccessConfirmation
