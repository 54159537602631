import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  List,
  Checkbox,
  Message,
  Icon,
  Transition,
  Popup,
} from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'

import { UPDATE_NOTIFICATIONS } from '../../graphql/expert'
import DataContext from '../../contexts/DataContext'

const Notifications = () => {
  const { advisor } = useContext(DataContext)

  const [newProjectNotify, setNewProjectNotify] = useState(
    advisor.expert.status !== 'Do Not Contact'
  )
  const [reminderNotify, setReminderNotify] = useState(
    advisor.expert.reminder_notify
  )
  const [paymentNotify, setPaymentNotify] = useState(
    advisor.expert.payment_notify
  )
  const [popoverOpener, setPopoverOpener] = useState(
    advisor.expert.status !== 'Do Not Contact'
  )
  const [isSaved, setIsSaved] = useState(false)

  const [updateNotifications, { loading }] = useMutation(UPDATE_NOTIFICATIONS)

  const onSave = () => {
    updateNotifications({
      variables: {
        input: {
          status: newProjectNotify ? 'Active' : 'Do Not Contact',
          reminder_notify: reminderNotify,
          payment_notify: paymentNotify,
        },
      },
      update: () => {
        MixpanelTracker.trackSetEmailNotifications(
          newProjectNotify,
          reminderNotify,
          paymentNotify
        )
        showMessage()
      },
    })
  }

  const showMessage = () => {
    setIsSaved(true)
    setTimeout(() => {
      setIsSaved(false)
    }, 3000)
  }

  return (
    <div>
      <Transition visible={isSaved} animation="scale" duration={500}>
        <Message success floating>
          <Message.Header>
            <Icon name="check" />
            Saved
          </Message.Header>
        </Message>
      </Transition>
      <div className="form-section">
        <h4>Emails from Dexter</h4>
        <h6>I&apos;d like to receive the following emails:</h6>
        <List>
          <List.Item>
            <Popup
              className="popover-content"
              open={!newProjectNotify && popoverOpener}
              trigger={
                <Checkbox
                  label={{ children: 'New projects invites' }}
                  checked={newProjectNotify}
                  onChange={() => {
                    setNewProjectNotify(!newProjectNotify)
                    setPopoverOpener(true)
                  }}
                />
              }
              on="click"
              content={
                <div>
                  Unchecking this box will change your status to{' '}
                  <strong>&quot;DO NOT CONTACT&quot;</strong> and you will no
                  longer receive new project invitations. Are you sure want to
                  continue?
                  <div>
                    <Button size="tiny" onClick={() => setPopoverOpener(false)}>
                      Yes
                    </Button>
                    <Button
                      size="tiny"
                      onClick={() => {
                        setNewProjectNotify(true)
                        setPopoverOpener(false)
                      }}
                    >
                      No
                    </Button>
                  </div>
                </div>
              }
            />
          </List.Item>
          <List.Item>
            <Checkbox
              label={{
                children: 'Reminder emails before my scheduled consultation',
              }}
              checked={reminderNotify}
              onChange={() => setReminderNotify(!reminderNotify)}
              id="reminder-notification"
            />
          </List.Item>
          <List.Item>
            <Checkbox
              label={{
                children: 'Payment confirmation emails',
              }}
              checked={paymentNotify}
              onChange={() => setPaymentNotify(!paymentNotify)}
              id="payment-confirmation-notification"
            />
          </List.Item>
        </List>
      </div>
      <Button
        primary
        floated="right"
        loading={loading}
        disabled={loading}
        onClick={onSave}
        className="profile-btn-section"
      >
        Save
      </Button>
    </div>
  )
}

export default Notifications
