import React, { useContext } from 'react'
import { Modal } from 'semantic-ui-react'
import AuthContext from '../contexts/AuthContext'
import CustomLink from '../components/CustomLink'

const UnverifiedEmail = () => {
  const { auth0User, logout } = useContext(AuthContext)
  return (
    <Modal open size="fullscreen">
      <Modal.Header>
        {`A verification email has been sent to ${auth0User.email}`}
      </Modal.Header>
      <Modal.Content>
        <p>
          Please check your inbox to verify your account. If you can’t find the
          email in your inbox or spam folder,
          {' contact us at '}
          <CustomLink>
            <a href="mailto:help@dexterexperts.com">help@dexterexperts.com.</a>
          </CustomLink>
        </p>
        <p>
          {'After verifying your email, return to this page and '}
          <CustomLink onClick={() => window.location.reload()}>
            refresh
          </CustomLink>
          {' it to access your account.'}
        </p>
        <p>
          If you encounter an error during verification, your email may already
          be verified.
          {' Try '}
          <CustomLink onClick={() => window.location.reload()}>
            refresh
          </CustomLink>
          {' this page'}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <CustomLink onClick={logout}>Logout</CustomLink>
      </Modal.Actions>
    </Modal>
  )
}

export default UnverifiedEmail
