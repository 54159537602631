import React, { useEffect, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { LOAD_SINGLE_MEETING_QUERY } from '../graphql/schedule'
import addToCalendar from '../utils/addToCalendar'
import DataContext from './DataContext'

const AddToCalendarContext = React.createContext({ handleAddToCalendar: null })
export const AddToCalendarProvider = ({ children }) => {
  const { advisor } = useContext(DataContext)
  const [getMeeting, { data }] = useLazyQuery(LOAD_SINGLE_MEETING_QUERY)

  useEffect(() => {
    if (data) {
      addToCalendar({
        user: advisor,
        job: data.job,
      })
    }
  }, [advisor, data])

  const handleAddToCalendar = (job) => {
    getMeeting({ variables: { id: job.id } })
    if (data) {
      addToCalendar({
        user: advisor,
        job: data.job,
      })
    }
  }
  return (
    <AddToCalendarContext.Provider value={{ handleAddToCalendar }}>
      {children}
    </AddToCalendarContext.Provider>
  )
}

export default AddToCalendarContext
